import React from 'react';
import { Link } from "react-router-dom";

export const MobileHeader = () => {
  return (
    <div>
      <header className="qodef-mobile-header qodef-animate-mobile-header mobile-header-appear" style={{marginBottom: 70}}>
				<div className="qodef-mobile-header-inner">
					<div className="qodef-mobile-header-holder">
						<div className="qodef-grid">
							<div className="qodef-vertical-align-containers">
								<div className="qodef-vertical-align-containers">
									<div className="qodef-mobile-menu-opener qodef-mobile-menu-opener-icon-pack">
										<a href="#"> <span className="qodef-mobile-menu-icon">
	<span aria-hidden="true" className="qodef-icon-font-elegant icon_menu "></span> </span>
										</a>
									</div>
									<div className="qodef-position-center" style={{textAlign:"left"}}>
										<div className="qodef-position-center-inner">
											<div className="qodef-mobile-logo-wrapper">
												<Link itemProp="url" to="/" style={{ marginLeft: 30}}>
                                                    <img itemProp="image" src="/images/logos/full_trimmed_transparent_black.png" alt="Mobile Logo" style={{marginLeft: 0,width:150}}/>
                                                </Link>
											</div>
										</div>
									</div>
									<div className="qodef-position-right">
										<div id="mobile-top-right-icons" style={{display:"none"}} className="qodef-position-right-inner">

{/* <!--<li id="nav-menu-item" className="menu-item menu-item-type-post_type menu-item-object-page  narrow"><a href="#" className=""><span className="item_outer"><span className="item_text"><i className="fas fa-user-alt"></i></span></span></a></li>--> */}

            <li className="nav-menu-item-cart menu-item menu-item-type-post_type menu-item-object-page  narrow">
            <span className="item_outer">
            <span className="cart-counter">5</span>
            <span className="item_text">
            <i id="nav-menu-item" className="fas fa-shopping-bag"></i>

{/* <!-- mini cart -->											     */}
 <div className="mini-cart-div mini-cart-wrapper">
 <p className="font_bold" style={{margin:"20px 15px",textAlign: "left"}}>YOU HAVE 5 ITEMS</p>
 <div className="mini-cart-area"> 
 
    <div className="col-xs-12"> 
    <div className="col-xs-3">
    <img src="https://m.media-amazon.com/images/I/41qZSwcABJL._AC_SS450_.jpg" style={{width:"100%"}}/>
    </div>
    <div className="col-xs-7">
    <p>2D Floor Plan</p>
    </div>
    <div className="col-xs-1">
    <p><i className="far fa-trash-alt"></i></p>
    </div>
    </div> 
    <div className="col-xs-12">  
    <hr/> 
    <div className="col-xs-3">
    <img src="https://m.media-amazon.com/images/I/41qZSwcABJL._AC_SS450_.jpg" style={{width:"100%"}}/>
    </div>
    <div className="col-xs-7">
    <p>3D Floor Plan</p>
    </div>
    <div className="col-xs-1">
    <p><i className="far fa-trash-alt"></i></p>
    </div>
    </div>
    <div className="col-xs-12">  
    <hr/> 
    <div className="col-xs-3">
    <img src="https://m.media-amazon.com/images/I/41qZSwcABJL._AC_SS450_.jpg" style={{width:"100%"}}/>
    </div>
    <div className="col-xs-7">
    <p>3D Floor Plan</p>
    </div>
    <div className="col-xs-1">
    <p><i className="far fa-trash-alt"></i></p>
    </div>
    </div>
    <div className="col-xs-12">  
    <hr/> 
    <div className="col-xs-3">
    <img src="https://m.media-amazon.com/images/I/41qZSwcABJL._AC_SS450_.jpg" style={{width:"100%"}}/>
    </div>
    <div className="col-xs-7">
    <p>3D Floor Plan</p>
    </div>
    <div className="col-xs-1">
    <p><i className="far fa-trash-alt"></i></p>
    </div>
    </div>
    <div className="col-xs-12">  
    <hr/> 
    <div className="col-xs-3">
    <img src="https://m.media-amazon.com/images/I/41qZSwcABJL._AC_SS450_.jpg" style={{width:"100%"}}/>
    </div>
    <div className="col-xs-7">
    <p>3D Floor Plan</p>
    </div>
    <div className="col-xs-1">
    <p><i className="far fa-trash-alt"></i></p>
    </div>
    </div>
    
 </div>    
    <div className="mini-cart-checkout">
        <p>TOTAL</p>
        <p>₹ 897</p>
       <a href="mycart.html"> <button className="custom-btn3">CHECKOUT</button></a> 
</div>
 </div>
            										    
            </span></span> 
            </li>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
 {/* <!-- mobile nav --> */}
	 
					<nav className="qodef-mobile-nav">
						<div className="qodef-grid">
							<ul id="menu-main-menu-navigation-2" className="">
								<li id="mobile-menu-item-5469" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-20 current_page_item qodef-active-item"><Link to="/" className=" current "><span>Home</span></Link></li>
								<li id="mobile-menu-item-5514" className="menu-item menu-item-type-post_type menu-item-object-page "><Link to="/" className=""><span>Projects</span></Link></li>
								<li id="l_solutions" className="  open menu-item menu-item-type-post_type menu-item-object-page narrow">
									<a className="limenu" href="" aria-expanded="true"> <span className="item_outer"><span className="item_text">Services<span className="caret"></span></span>
										</span>
									</a>
									<ul className="dropdown-menu">
										<li><Link to="/interior">Interiors</Link></li>
										<li><a href="javascript:void(0);" title={"Coming Soon"}>Construction</a></li>
										<li><Link to="/civil">Civil</Link></li>
										<li><Link to="/architecture">Architecture</Link></li>
										<li><Link to="/painting">Painting</Link></li>
										<li><a href="https://wallpap.in" target={"_blank"}>Wallpapers</a></li>
									</ul>
								</li>
								<li style={{display:"none"}} id="mobile-menu-item-5833" className="menu-item menu-item-type-post_type menu-item-object-page "><Link to="/" className=""><span>Blog</span></Link></li>
								<li style={{display:"none"}} id="mobile-menu-item-5832" className="menu-item menu-item-type-post_type menu-item-object-page "><Link to="/" className=""><span>Contact</span></Link></li>
								<li style={{display:"none"}} id="mobile-menu-item-5832" className="menu-item menu-item-type-post_type menu-item-object-page "><Link to="/" className=""><span>Login</span></Link></li>
							{/* <!--	<li id="mobile-menu-item" className="menu-item menu-item-type-post_type menu-item-object-page "> 
    								<div className="mobile-nav-icons"><span>
                                    <a> <i className="fas fa-user-alt"></i> </a> 
                                    <a className="mobile-nav-cart"> <i className="fas fa-shopping-bag"></i> </a> 
                                    </span>
                                    </div>
                                </li> --> */}
							</ul>
						</div>
					</nav>
				</div>
			</header>
    </div>
  )
}
