import React from 'react';

export const PaintCard = ({title, value, setValue}) => {
  console.log("value is",value);
  return <div>
    <div className="q-cal card">
                <h5> {title}:</h5>
                <hr />

                <div className="QpaintRate fresh_paint active">
                  <div
                    className="container-fluid"
                    style={{ padding: "0 10px" }}
                  >
                    
                    {value.material_type.map((ele, idx)=>(
                    <div key={idx} className="col-xs-12 col-sm-4"
                    onClick={()=>{
                      setValue(
                        {...value, 
                        selectedMaterialType : ele,
                        selectedMaterial : value[ele][0],
                        })}}>
                      <label className="radio-inline custom-checkbox">
                        <input type="radio" name={`${title}`} />
                        <span className="checkmark"></span> {ele}
                      </label>
                    </div>
                    ))}

                    
                  </div>
                  <div className="form-group mb-25 " style={{ margin: 10 }}>
                    <select className="form-control select_paint" 
                      onChange={(e)=>{
                        console.log("material option selected", e.target.value,);
                        setValue({...value, selectedMaterial : value[value.selectedMaterialType][e.target.value]});
                        }}>
                      {(value[value.selectedMaterialType] || []).map((opt, idx)=>(
                        <option key={idx} value={idx}
                          onClick={()=>{
                          }}
                        >
                          {opt.material} | &#8377; {opt.rate}/Sqft.</option>
                      ))}
                    </select>
                  </div>
                </div>
                
              </div>

  </div>;
};
