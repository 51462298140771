import React, { useState, useEffect } from "react";


export const QuickCalculator = ({
  id,
  title,
  color,
  serviceEstimate,
  setServiceEstimate,
  plotArea,
  setPlotArea,
  builtupArea,
  setBuiltupArea,
  total,
  setTotal,
  rate,
}) => {

  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [nFloors, setNFloors] = useState(1);

  console.log("rate::",rate,rate[title])
  

  const setMyServiceEstimate = (value) => {
    let _serviceEstimate = JSON.parse(JSON.stringify(serviceEstimate));
    _serviceEstimate[title] = value;
    setServiceEstimate(_serviceEstimate);
    let _total = Object.keys(_serviceEstimate).reduce((sum, key)=>sum+_serviceEstimate[key], 0);
    setTotal(_total);
  };

  const changeLength = (value) => {
    setLength(value);
    setPlotArea(width * value);
    setBuiltupArea(width * value * nFloors);
    setMyServiceEstimate(rate[title] * width * value * nFloors);
  };
  const changeWidth = (value) => {
    setWidth(value);
    setPlotArea(value * length);
    setBuiltupArea(value * length * nFloors);
    setMyServiceEstimate(rate[title] * value * length * nFloors);
  };
  const changeNFloors = (value) => {
    setNFloors(value);
    setBuiltupArea(plotArea * value);
    setMyServiceEstimate(rate[title] * plotArea * value);
  };

  //using API for id get the rate[title]
  useEffect(async () => {
    console.log("useEffect....");
    // effect
    let _total = Object.keys(serviceEstimate).reduce((sum, key)=>sum + serviceEstimate[key], 0);
    setTotal(_total);

    return () => {
      // cleanup
    };
  }, []);


  return (
    <div
      className="q-cal card"
    >
      <h5>Step 2: Area</h5>
      <hr />
      <div className="row">
        <div className="col-xs-6 col-sm-6">
          <label className="radio-inline">
            <label htmlFor="usr">Width</label>
            <input
              type="number"
              name="width"
              value={length}
              onChange={(e) => changeLength(e.target.value)}
            />
            &nbsp; <span className="ipunits">Ft.</span>{" "}
          </label>
        </div>
        <div className="col-xs-6 col-sm-6">
          <label className="radio-inline">
            <label htmlFor="usr">Length</label>
            <input
              type="number"
              name="length"
              value={width}
              onChange={(e) => changeWidth(e.target.value)}
            />
            &nbsp; <span className="ipunits">Ft.</span>{" "}
          </label>
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className="radio-inline">
            <label>Plot Area</label>
            <input
              type="number"
              name="plotArea"
              readOnly={true}
              value={plotArea}
            />
            {/* &nbsp; <span className="ipunits">Sqft.</span>{" "} */}
          </label>
        </div>
        <div className="col-xs-12 col-sm-6">
          <label className="radio-inline">
            <label>No of Floors </label>
            <input
              type="number"
              name="numfloors"
              value={nFloors}
              onChange={(e) => changeNFloors(e.target.value)}
            />
            &nbsp;{" "}
          </label>
        </div>
      </div>
    </div>
  );
};
