import React from "react";

export const Footer = () => {
  return (
    <footer className="qodef-page-footer" style={{ zIndex: 100 }}>
      <div className="qodef-footer-top-holder">
        <div className="qodef-footer-top-inner qodef-full-width">
          <div className="qodef-grid-row qodef-footer-top-alignment-left">
            <div className="qodef-column-content qodef-grid-col-12">
              <div
                id="media_image-4"
                className="widget qodef-footer-column-1 widget_media_image"
              >
                <a href="#">
                  {" "}
                  <img
                    width="300"
                    height="63"
                    src="/images/logos/full_margin_transparent_white.png"
                    className="image wp-image-5894  attachment-medium size-medium"
                    alt=""
                    loading="lazy"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />{" "}
                </a>
              </div>
              <div
                id="text-2"
                className="widget qodef-footer-column-1 widget_text"
              >
                <div className="textwidget">
                  <p>
                    <strong>India</strong>
                  </p>
                  <p>404, Konark Towers, Dilsukhnagar, Hyderabad</p>
                  <p>+91 91004 46450 </p>
                  <p>info@incociar.com</p>
                </div>
              </div>
              <div className="widget qodef-social-icons-group-widget text-align-center">
                <a
                  className="qodef-social-icon-widget-holder qodef-icon-has-hover"
                  data-hover-color="#a4a4a4"
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "0px 6px",
                  }}
                  href="https://instagram.com/incociar"
                  target="_blank"
                >
                  {" "}
                  <span className="qodef-social-icon-widget social_instagram"></span>{" "}
                </a>
                <a
                  className="qodef-social-icon-widget-holder qodef-icon-has-hover"
                  data-hover-color="#a4a4a4"
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "0px 6px",
                  }}
                  href="https://www.facebook.com/Incociar"
                  target="_blank"
                >
                  {" "}
                  <span className="qodef-social-icon-widget social_facebook"></span>{" "}
                </a>
                <a
                  className="qodef-social-icon-widget-holder qodef-icon-has-hover"
                  data-hover-color="#a4a4a4"
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "0px 6px",
                  }}
                  href="https://pin.it/2hWW8Cu"
                  target="_blank"
                >
                  {" "}
                  <span className="qodef-social-icon-widget social_pinterest"></span>{" "}
                </a>
                <a
                  className="qodef-social-icon-widget-holder qodef-icon-has-hover"
                  data-hover-color="#a4a4a4"
                  style={{
                    color: "white",
                    fontSize: "14px",
                    margin: "0px 6px",
                  }}
                  href="https://youtube.com/@incociar4703"
                  target="_blank"
                >
                  {" "}
                  <span className="qodef-social-icon-widget social_youtube"></span>{" "}
                </a>
              </div>
              <div className="widget qodef-separator-widget">
                <div className="qodef-separator-holder clearfix  qodef-separator-center qodef-separator-normal">
                  <div
                    className="qodef-separator"
                    style={{ borderStyle: "dashed" }}
                  ></div>
                </div>
              </div>
              <div
                id="text-7"
                className="widget qodef-footer-column-1 widget_text"
              >
                <div className="textwidget">
                  <p>
                    <a
                      href="privacy-policy.html"
                      target="_blank"
                      rel="noopener"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </div>
              <div
                id="text-7"
                className="widget qodef-footer-column-1 widget_text"
              >
                <div className="textwidget">
                  <p>
                    <a href="Copyright.html" target="_blank" rel="noopener">
                      Copyright
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
