import React, { useEffect, useState, useContext, useReducer } from "react";
import { roomReducer } from "../components/RoomState";
import { RoomDim } from "../components/RoomDim";
import {Api} from "../libs/Api";
import "../libs/Nm_Events";
import { PaintCard } from "../components/PaintCard";
import { PaintingEstimationCard } from "../components/PaintingEstimationCard";

export const PaintingCalculatorPg = () => {
  const [flavor, setFlavor] = useState("quick");
  // const [flavor, setFlavor] = useState("advance");
  const [projectType, setProjectType] = useState('fresh');
  function changeProjectType(val){
    setProjectType(val);
    // resetRates(val);
    // quickCalculator(val, carpetArea, paint);
  }
  const [space, setSpace] = useState("interior");
  const [email,setEmail] = useState(localStorage.getItem("email") || "") ;

  const [paint, setPaint] = useState({material_type : [] });
  function changePaint(val){
    setPaint(val);
    // quickCalculator(projectType, carpetArea, val);
  }
  const [texture, setTexture] = useState({material_type : [] });
  const [putty, setPutty] = useState({material_type : [] });
  const [primer, setPrimer] = useState({material_type : [] });

  const newRoom = {
    name : "Room 1",
    width : 0,
    len : 0,
    height : 0,
    doors : {
      wallA:[],
      wallB:[],
      wallC:[],
      wallD:[],
    },
    windows : {
      wallA:[],
      wallB:[],
      wallC:[],
      wallD:[],
    },
    wallA : { work: "Paint", width: 0, height : 0 , wallArea : 0, noWallArea : 0 },
    wallB : { work: "Paint", width: 0, height : 0 , wallArea : 0, noWallArea : 0 },
    wallC : { work: "Paint", width: 0, height : 0 , wallArea : 0, noWallArea : 0 },
    wallD : { work: "Paint", width: 0, height : 0 , wallArea : 0, noWallArea : 0 },
    includeCeiling : false,
    totalArea : 0,
    wallArea : 0,
    noWallArea : 0,
  }
  const newWindow = {
    name : "Window ",
    width : 0,
    height : 0,
  }
  const newDoor = {
    name : "Door ",
    width : 0,
    height : 0,
  }
  const [roomsDetails, setRoomsDetails] = useState([newRoom]); 
  const [carpetArea, setCarperArea] = useState(0); 
  
  function changeCarperArea (val){
    setCarperArea(val);
    // quickCalculator(projectType, val, paint);
  }

  const [total, setTotal] = useState(0);
  const [quickTotal, setQuickTotal] = useState(0);
  const [area, setArea] = useState(0);
  const [quickArea, setQuickArea] = useState(0);

  const nCoats = 2; 

  const createState = (data) => {
    let _state = {
      material_type:[],
      selectedMaterialType : "",
      selectedMaterial : "",
    };
    data.forEach ((ele, idx)=>{
      
      if (_state[ele.material_type] ==  undefined){
        _state.material_type.push(ele.material_type);
        _state[ele.material_type] = [];
      }
      _state[ele.material_type].push(ele);

      if (idx == 0 ){
        _state.selectedMaterialType = ele.material_type;
        _state.selectedMaterial = ele;
      }
    })

    return _state;
  }

  useEffect(async()=>{
    if (!putty["Standard"] || !primer["Standard"]) return
    resetPaintRates(paint,projectType);
    resetTextureRates(texture,projectType);
  }, [primer,putty,projectType]);
  
  
  const resetPaintRates = (paintState, projectType) => {
    let _paint = {...paintState};
    _paint.material_type.forEach ((materials, idx)=>{
      _paint[materials].forEach((ele)=>{
        if (ele.rateOrig == undefined){
          ele.rateOrig = ele.rate;
          ele.rateFresh = ele.rate + putty["Standard"][0].rate + primer["Standard"][0].rate;
          ele.rateRepaint = ele.rate + putty["Standard"][0].rate;
        }
        if ( projectType == "fresh"){
            ele.rate = ele.rateFresh;
        }else {
          ele.rate = ele.rateRepaint; 
        }
      })
    })
    setPaint(_paint);
  }
  const resetTextureRates = (textureState, projectType) => {
    let _texture = {...textureState};
    _texture.material_type.forEach ((materials, idx)=>{
      _texture[materials].forEach((ele)=>{
        if (ele.rateOrig == undefined){
          ele.rateOrig = ele.rate;
          ele.rateFresh = ele.rate + putty["Standard"][0].rate + primer["Standard"][0].rate;
          ele.rateRepaint = ele.rate + putty["Standard"][0].rate;
        }
        if ( projectType == "fresh"){
            ele.rate = ele.rateFresh;
        }else {
          ele.rate = ele.rateRepaint; 
        }
      })
    })
    setTexture(_texture);
  }
  useEffect(async()=>{
    if (!putty["Standard"] || !primer["Standard"]) return
    resetPrimerRates(primer,projectType);
  }, [putty,projectType]);
  
  const resetPrimerRates = (primerState, projectType) => {
    let _primer = {...primerState};
    _primer.material_type.forEach ((materials, idx)=>{
      _primer[materials].forEach((ele)=>{
        if (ele.rateOrig == undefined){
          ele.rateOrig = ele.rate;
          ele.rateFresh = ele.rate + putty["Standard"][0].rate;
          ele.rateRepaint = ele.rate;
        }
        if ( projectType == "fresh"){
            ele.rate = ele.rateFresh;
        }else {
          ele.rate = ele.rateRepaint; 
        }
      })
    })
    setPrimer(_primer);
  }

  const quickCalculator = () => {
    let _area = carpetArea * 3.5 
    setQuickArea(_area);
    const _total = _area * paint.selectedMaterial.rate|0;
    setQuickTotal(_total);
    console.log("quickCalculator",paint.selectedMaterial.rate, _area, _total, )
  }

  useEffect(async()=>{
    if (!paint.selectedMaterial) return;
    quickCalculator();
  }, [carpetArea, paint]);
  
  const quickCalculatorX = (newProjectType, newCarpetArea, newPaint) => {
    if (newProjectType == "fresh"){
      const _total = newCarpetArea * ( 
        putty["Standard"][0].rate + 
        primer["Standard"][0].rate + 
        newPaint.selectedMaterial.rate )  
        * nCoats;
      setTotal(_total);  
    }else{
      const _total = newCarpetArea * ( 
        // putty["Standard"][0].rate + 
        primer["Standard"][0].rate + 
        newPaint.selectedMaterial.rate )  
        * nCoats;
      setTotal(_total);
    }
  }

  useEffect(()=>{
    console.log("calculate total Rs", );
    let _total = roomsDetails.reduce((sum, room)=> Number(sum) + Number(room.totalPrice) ,0);
    setTotal(_total);
    let _area = roomsDetails.reduce(
      (sum, room) => Number(sum) + Number(room.paintArea),0);
    setArea(_area);
  },[roomsDetails]);
  
  useEffect(async()=>{
    let response;
    let _state = {material_type:[]};
    response = await Api.get_all("painting_rates","service=Paint");
    console.log("painting rates response:",response);
    setPaint(createState(response.data));

    response = await Api.get_all("painting_rates","service=Texture");
    console.log("Texture rates response:",response);
    setTexture(createState(response.data));

    response = await Api.get_all("painting_rates","service=Putty");
    console.log("Putty rates response:",response);
    setPutty(createState(response.data));

    response = await Api.get_all("painting_rates","service=Primer");
    console.log("Primer rates response:",response);
    setPrimer(createState(response.data));


    window.NM_EVENTS.subscribe("USER_LOGGED_IN", ()=>{
      setEmail(localStorage.getItem("email") || "");
    });

  },[])

  return (
    <div>
      <link href="/css/painting-calculator.css" rel="stylesheet" type="text/css" />

      <div id="paint-calc">
        <div className="container">
          <div
            className="col-sm-8 col-xs-12 col-steps"
            style={{ marginTop: 35 }}
          >
            <div className="card">
              {/* <!--<h5 style={{font: 30px/0.85 'Titling Gothic Skyline', Arial, Helvetica, sans-serif;color: #f17b49;">3D CALCULATOR</h5>--> */}
              <h5>Painting Estimator</h5>
              <hr />
              <h5>Step 1: Select Calculator</h5>
              <div className="form-group mb-25">
                <select
                  className="form-control select_cal"
                  value = {flavor}
                  onChange={(e) => setFlavor(e.target.value)}
                >
                  <option value="quick">Quick Calculator</option>
                  <option value="advance">Advance Calculator</option>
                </select>
              </div>
            </div>

            <div className="q-cal card">
                <h5> Type of project:</h5>
                <hr />
                <div className="container-fluid" style={{ padding: "0 10px" }}>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input
                        type="radio"
                        name="q_paintingtype"
                        value="fresh"
                        checked={projectType == "fresh"}
                        onChange={(e)=>{changeProjectType(e.target.value)}}
                      />
                      <span className="checkmark"></span> Fresh Painting <br />
                      <span style={{ fontSize: 13 }}>
                        {" "}
                        (Includes two coats each - primer, putty and fresh
                        paint.){" "}
                      </span>{" "}
                    </label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input
                        type="radio"
                        name="q_paintingtype"
                        value="repaint"
                        checked={projectType == "repaint"}
                        onChange={(e)=>{changeProjectType(e.target.value)}}
                      />{" "}
                      <span className="checkmark"></span> Re Painting <br />
                      <span style={{ fontSize: 13 }}>
                        {" "}
                        (Includes two coats each - primer and fresh paint.){" "}
                      </span>{" "}
                    </label>
                  </div>
                </div>
              </div>

            <div style={{ display: flavor == "quick" ? "block" : "none" }}>
              

              <div className="q-cal card hide">
                <h5> Select space:</h5>
                <hr />
                <div className="container-fluid" style={{ padding: "0 10px" }}>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input type="radio" name="myspace" />
                      <span className="checkmark"></span> Interior <br />
                      <span style={{ fontSize: 13 }}>
                        {" "}
                        (Includes two coats each - primer, putty and fresh
                        paint.){" "}
                      </span>{" "}
                    </label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input type="radio" name="myspace" />{" "}
                      <span className="checkmark"></span> Exterior <br />
                      <span style={{ fontSize: 13 }}>
                        {" "}
                        (Includes two coats each - primer and fresh paint.){" "}
                      </span>{" "}
                    </label>
                  </div>
                </div>
              </div>

              <div className="q-cal card hide">
                <h5> Home Configuration:</h5>
                <hr />
                <div className="container-fluid" style={{ padding: "0 10px" }}>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input type="radio" name="myview" />
                      <span className="checkmark"></span> 1 BHK
                    </label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input type="radio" name="myview" />{" "}
                      <span className="checkmark"></span> 2 BHK{" "}
                    </label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input type="radio" name="myview" />{" "}
                      <span className="checkmark"></span> 3 BHK or More{" "}
                    </label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input type="radio" name="myview" />{" "}
                      <span className="checkmark"></span> Commercial{" "}
                    </label>
                  </div>
                </div>
              </div>

              <div className="q-cal card">
                <h5> Carpet</h5>
                <hr />
                <div className="row">
                  <div className="col-xs-6 col-sm-6">
                    <label className="radio-inline">
                      <label for="usr">Area</label>
                      <input type="number" name="qwidth" 
                      value={carpetArea}
                      onChange={(e)=>changeCarperArea(e.target.value)}
                      />
                      &nbsp; <span className="ipunits">SQFt.</span>{" "}
                    </label>
                  </div>
                </div>
              </div>

              <PaintCard title="Paint" key="quick" value={paint} setValue={changePaint}/>

            </div>

            <div style={{ display: flavor == "quick" ? "none" : "block" }}>

              <div className="a-cal card">
                <h5> Select space:</h5>
                <hr />
                <div className="container-fluid" style={{ padding: "0 10px" }}>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input
                        type="radio"
                        name="myspace"
                        value="interior"
                        checked ={space=="interior"?true:false}
                        onChange={(e)=>setSpace(e.target.value)}
                      />
                      <span className="checkmark"></span> Interior <br />
                      <span style={{ fontSize: 13 }}>
                        {" "}
                        (Includes two coats each - primer, putty and fresh
                        paint.){" "}
                      </span>{" "}
                    </label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <label className="radio-inline custom-checkbox">
                      <input type="radio" 
                        name="myspace" 
                        value="exterior" 
                        checked ={space=="exterior"?true:false}
                        onChange={(e)=>setSpace(e.target.value)}
                      />{" "}
                      <span className="checkmark"></span> Exterior{" "}
                    </label>
                  </div>
                </div>
              </div>

              <RoomDim roomsDetails={roomsDetails} setRoomsDetails={setRoomsDetails} paint={paint} setPaint={setPaint} texture={texture} setTexture={setTexture} primer={primer} setPrimer={setPrimer} projectType={projectType} space={space} putty={putty}/>

              {/* <PaintCard title="Paint" key="advance" value={paint} setValue={changePaint}/> */}

              

            </div>


          </div>
          <div
            className="col-sm-4 col-xs-12 calc2"
            style={{ position: "fixed", right: 20, marginTop: 35 }}
          >
            <div style={{ display: flavor == "advance" ? "block" : "none" }}>
              <PaintingEstimationCard key="advanced-estimation" roomsDetails={roomsDetails} total={total} area={area}/>
            </div>
            <div style={{ display: flavor == "quick" ? "block" : "none" }}>
              <PaintingEstimationCard key="quick-estimation" roomsDetails={[]} total={quickTotal} area={quickArea}/>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
