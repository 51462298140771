export const Pg = {
  civil: {
    banners: 4,
    calculators: [
      "Structural Drawing",
      "Working Drawing",
      "Electrical Drawing",
      "Plumbing Drawing",
    ],
    service_text: [
      "Our team of experienced engineering experts helps you with the best and most detailed structural drawings for your projects. Now create flawless structures with us.",
      "We help you create the most efficient working drawings for the blueprint of your projects. Our engineers use advanced technology tools, design aesthetics, and Vaastu recommendations to place each element in the project in the right position.",
      "Get a picture-perfect layout of the electrical blueprint for your project by our engineering ninjas. We create the most sustainable, luminous, energy-efficient, and cost-effective visual representation of the circuits and electrical systems tailored for each project",
      "We help you layout the plumbing system by creating a well-defined plumbing drawing for your construction project. The blueprints exhibit every plumbing connection, fixtures, fittings, etc., in the most appropriate positions based on project dimensions.",
    ],
  },
  architecture: {
    banners: 5,
    calculators: [
      "2D Floor Plan",
      "3D Floor Plan",
      "3D Elevation",
      "Designing",
      "Walkthrough",
    ],
    service_text: [
      "Our qualified architects prioritise your needs, envisage your dream design and create the most outstanding design for your construction projects in a 2D scheme as the 1st step towards giving shape to your ideas and goals.",
      "To offer a more realistic and picturesque visualization of the project, we use advanced software to present a 3D floor plan to you. Through 3D plan, we can beautifully represent your dream goals, giving you a complete view about the true beauty and sophistication. Our design ensure to compliment your personality and goals.",
      "To help you visualise your dream home or commercial complex better, we offer a 3D elevation of the design you have approved. This helps to prune the design and imagine chasms and elevate the transparency level.",
      "We have a stellar team of architects and interior designers who are always psyched up to come up with elite designs. Our team spends ample time to understand every minute detailed requirement of the clients. We marry aesthetics and modern technology to come up with the best design counsel for our clients.",
    ],
  },
  painting: {
    banners: 2,
    calculators: [
      "2D Floor Plan",
      "3D Floor Plan",
      "3D Elevation",
      "Designing",
    ],
    service_text: [
      "Our qualified architects prioritise your needs, envisage your dream design and create the most outstanding design for your construction projects in a 2D scheme as the 1st step towards giving shape to your ideas and goals.",
      "To offer a more realistic and picturesque visualization of the project, we use advanced software to present a 3D floor plan to you. Through 3D plan, we can beautifully represent your dream goals, giving you a complete view about the true beauty and sophistication. Our design ensure to compliment your personality and goals.",
      "To help you visualise your dream home or commercial complex better, we offer a 3D elevation of the design you have approved. This helps to prune the design and imagine chasms and elevate the transparency level.",
      "We have a stellar team of architects and interior designers who are always psyched up to come up with elite designs. Our team spends ample time to understand every minute detailed requirement of the clients. We marry aesthetics and modern technology to come up with the best design counsel for our clients.",
    ],
  },
};
