import React, { useState, useEffect, useContext } from "react";
import { Api } from "../libs/Api";
import { useNavigate } from "react-router-dom";
import "../libs/Nm_Events";
import { UserCtx, ACTION } from "../libs/UserCtx";


export const LoginModal = () => {
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {user, dispatch} =  useContext(UserCtx);

  const isLoginPg = window.location.pathname === "/login";

  let redirect = window.location.href.split("redirect=")[1] || "";
  redirect = redirect.trim();
  // console.log("slug", redirect);

  const clearError = () => {
    setEmailError(false);
    setPasswordError(false);
    setErrorMessage("");
  };
  const checkEmail = () => {
    if (email.indexOf("@") < 0) {
      setEmailError(true);
      return false;
    }
    if (email.indexOf(".") < 0) {
      setEmailError(true);
      return false;
    }
    if (email.length < 5) {
      setEmailError(true);
      return false;
    }

    return true;
  };
  const checkPassword = () => {
    if (password.length < 3) {
      setPasswordError(true);
      return false;
    }
    return true;
  };

  const createAccount = async () => {
    console.log("creating account with", email, password);
    clearError();
    if (!checkEmail()) return;
    if (!checkPassword()) return;

    const params = { email: email.trim(), password: password.trim() };

    try {
      let response = await Api.createAccount(params);

      console.log("response", response);
      if (response.data.error) {
        setErrorMessage(response.data.error);
        setEmailError(true);
        return;
      } else {
        //update referal points and referedCustomers

        const html = `
        Hi there
        Welcome to incociar.com
        login : ${email.trim()}
        password: ${password.trim()}
        Regards
        The Incociar Team`;

        //send email
        const msg = {
          to: email.trim(),
          from: "info@incociar.com", // Use the email address or domain you verified above
          subject: "Welcome to Incociar",
          // text: "and easy to do anywhere, even with Node.js",
          html: html,
        };

        response = Api.email({ email: msg });
        login();
      }
    } catch (e) {
      console.log("create account error", e);
      setErrorMessage("Create account failed!");
    }
  };
  let navigate = useNavigate();

  async function login() {
    const params = { email: email.trim(), password: password.trim() };
    console.log("log in with", email, password);
    // window.jQuery('#myLoginModal').modal("hide");

    try {
      clearError();
      if (!checkEmail()) return;
      if (!checkPassword()) return;

      const response = await Api.authenticate(params);
      localStorage.setItem("email", params.email);
      dispatch( {type : ACTION.LOGIN, payload: params.email });
      if (isLoginPg){
        if (redirect == ""){
          window.location.href = '/';
        }
        else {
          navigate("/" + redirect);
        }
      }else{
        window.$('#myLoginModal').modal("hide");
        window.NM_EVENTS.publish("USER_LOGGED_IN");
        
      }
    } catch (e) {
      console.log("Login error", e);
      console.log("Login error response", e.response);
      setErrorMessage("Login failed! " + e.response.data.error);
    }
  }

  useEffect(async () => {
    console.log("useEffect....");
    // effect
    return () => {
      // cleanup
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="col-md-6">
        <div>
          {!isNewAccount ? (
            <p className="login_title">
              <b>
                New to Incociar?
                <br />
                Please sign up here
              </b>
            </p>
          ) : (
            <p className="login_title">
              <b>
                Already have an account?
                <br />
                Please login here
              </b>
            </p>
          )}
          <br />
          <div className="text-center custom-btn3_left">
            {!isNewAccount ? (
              <button
                onClick={(e) => {
                  setIsNewAccount(true);
                }}
                style={{ width: 200 }}
              >
                Create new account
              </button>
            ) : (
              <button
                onClick={(e) => {
                  setIsNewAccount(false);
                }}
                style={{ width: 200 }}
              >
                Login
              </button>
            )}
          </div>
          <hr className="mview" />
          <img
            src="images/login-drawing.jpg"
            className="img-responsive dview"
          />
        </div>
      </div>

      <div className="col-md-6" style={{ borderLeft: "1px solid #ccc" }}>
        <div>
          {!isNewAccount ? (
            <p className="login_title">
              <b>
                Already have an account?
                <br />
                Please login here
              </b>
            </p>
          ) : (
            <p className="login_title">
              <b>
                New to Incociar?
                <br />
                Please sign up here
              </b>
            </p>
          )}
          <br />
          <label>E-mail</label>
          <input
            style={emailError ? { border: "1px solid red" } : {}}
            type="text"
            name="user_login"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Password:</label>
          <input
            style={passwordError ? { border: "1px solid red" } : {}}
            type="password"
            name="user_pass"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p>
            <small>
              Forgot your password? Click <a href="#">here</a>
            </small>
          </p>
          {isNewAccount == false ? (
            <button
              className="custom-btn3"
              type="submit"
              style={{ backgroundColor: "#4296c7" }}
              onClick={() => login()}
            >
              Log in
            </button>
          ) : (
            <button
              className="custom-btn3"
              type="submit"
              style={{ backgroundColor: "#4296c7" }}
              onClick={() => createAccount()}
            >
              Create New Account
            </button>
          )}
        </div>
        <span style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</span>
      </div>
    </div>
  );
};
