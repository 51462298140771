import React, { useEffect, useState, useContext, useReducer } from "react";

export const RoomWindowsDoors = ({roomDetails,changeRoomDetails}) => {
  const [selectedWall, setSelectedWall] = useState("A");
  const [show, setShow] = useState(false);
  const newWindow = {
    name : "Window ",
    width : "",
    height : "",
  }
  const newDoor = {
    name : "Door ",
    width : "",
    height : "",
  }
  function addWindow(wall){
    let _roomDetails = {...roomDetails};
    // const nWindows = _roomDetails.windows[wall].length + 1;
    // let _newWindow = {...newWindow, name: `Window ${nWindows}`}
    _roomDetails.windows[wall].push(newWindow);
    changeRoomDetails(_roomDetails)
  }
  function removeWindow(idx,wall){
    let _roomDetails = {...roomDetails};
    _roomDetails.windows[wall].splice(idx,1);
    changeRoomDetails(_roomDetails)
  }
  function addDoor(wall){
    let _roomDetails = {...roomDetails};
    _roomDetails.doors[wall].push(newDoor);
    changeRoomDetails(_roomDetails)
  }
  function removeDoor(idx,wall){
    let _roomDetails = {...roomDetails};
    _roomDetails.doors[wall].splice(idx,1);
    changeRoomDetails(_roomDetails)
  }

  function changeWindowDims(idx,{wall,height , width}){
    let _roomDetails = {...roomDetails};
    _roomDetails.windows[wall][idx].height = height;
    _roomDetails.windows[wall][idx].width = width;
    changeRoomDetails(_roomDetails);
  }
  function changeDoorDims(idx,{wall,height , width}){
    let _roomDetails = {...roomDetails};
    _roomDetails.doors[wall][idx].height = height;
    _roomDetails.doors[wall][idx].width = width;
    changeRoomDetails(_roomDetails);
  }

  console.log("roomDetails RoomWindowsDoors",roomDetails);
  return (
    <div className="col-xs-12 ptb-10 add_DW_div">
      <p onClick={()=>setShow(!show)}>
        <span className="btntag add_DW_btn">{!show?"Add ":"Hide "} Windows/ Doors</span>
      </p>
      {show?
      <>
      {["A", "B", "C", "D"].map((wName, idx) => (
        <div className="form-inline ">
          <div className="form-group">
            <label for="email">WALL {wName}</label>
            <span className="row-modifier addWindow" for="usr"
            onClick ={()=>addWindow(`wall${wName}`)}>
              <i className="fas fa-plus-circle"></i> Window{" "}
            </span>
            <span className="row-modifier addDoor" for="usr"
            onClick ={()=>addDoor(`wall${wName}`)}>
              <i className="fas fa-plus-circle"></i> &nbsp;Door{" "}
            </span>
          </div>

          {roomDetails.windows[`wall${wName}`].map((win,idx)=>(
            <div key={idx} className="wall_DW wall_A_DW" style={{ marginTop: 5 }}>
              {/* <!-- <div className="_DW"> */}
              <label className="col-xs-12">
                Window {idx+1} <i className="DW_close fas fa-minus-circle"
                  onClick={()=>removeWindow(idx, `wall${wName}`)}
                ></i>
              </label>
              <div className="col-xs-6 col-sm-6">
                {" "}
                <label className="radio-inline">
                  {" "}
                  <label for="usr">Width</label>{" "}
                  <input type="text" name="width" 
                    value={win.width}
                    onChange={(e) => changeWindowDims(idx,{wall:`wall${wName}`,width:e.target.value, height:win.height})}
                  />
                  &nbsp; <span className="ipunits">Ft.</span>{" "}
                </label>
              </div>
              <div className="col-xs-6 col-sm-6">
                {" "}
                <label className="radio-inline">
                  {" "}
                  <label for="usr">Height</label>{" "}
                  <input type="text" name="winHeight" 
                    value={win.height}
                    onChange={(e) => changeWindowDims(idx,{wall:`wall${wName}`,width:win.width, height:e.target.value})}
                  />
                  &nbsp; <span className="ipunits">Ft.</span>{" "}
                </label>
              </div>
              {/* </div> --> */}
            </div>
          ))}
          
          {roomDetails.doors[`wall${wName}`].map((door,idx)=>(
            <div key={idx} className="wall_DW wall_A_DW" style={{ marginTop: 5 }}>
              {/* <!-- <div className="_DW"> */}
              <label className="col-xs-12">
                Door {idx+1} 
                <i className="DW_close fas fa-minus-circle"
                  onClick ={()=>removeDoor(idx,`wall${wName}`)}>
                </i>
              </label>
              <div className="col-xs-6 col-sm-6">
                {" "}
                <label className="radio-inline">
                  {" "}
                  <label for="usr">Width</label>{" "}
                  <input type="text" name="width" 
                    value={door.width}
                    onChange={(e) => changeDoorDims(idx,{wall:`wall${wName}`,width:e.target.value, height:door.height})}
                  />
                  &nbsp; <span className="ipunits">Ft.</span>{" "}
                </label>
              </div>
              <div className="col-xs-6 col-sm-6">
                {" "}
                <label className="radio-inline">
                  {" "}
                  <label for="usr">Height</label>{" "}
                  <input type="text" name="doorHeight" 
                      value={door.height}
                      onChange={(e) => changeDoorDims(idx,{wall:`wall${wName}`,width:door.width, height:e.target.value})}
                  />
                  &nbsp; <span className="ipunits">Ft.</span>{" "}
                </label>
              </div>
              {/* </div> --> */}
            </div>
          ))}

        </div>
      ))}
      </>
      :
      ""}

    </div>
  );
};
