import React from "react";
import {Config} from "../Config"


export const Featured = ({ images }) => {
  return (
    <div>
      <div
        className="qodef-row-grid-section-wrapper "
        style={{ paddingTop: 80, opacity: 1 }}
      >
        <div className="qodef-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_custom_des">
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div
                    className="qodef-section-title-holder  qodef-st-corners qodef-st-title-left qodef-st-normal-space  "
                    style={{ textAlign: "center" }}
                  >
                    <div className="qodef-st-inner">
                      {" "}
                      <span className="qodef-corner-line line-1"></span>{" "}
                      <span className="qodef-corner-line line-2"></span>{" "}
                      <span className="qodef-corner-line line-3"></span>{" "}
                      <span className="qodef-corner-line line-4"></span>
                      <h2 className="qodef-st-title">
                        {" "}
                        Featured Projects{" "}
                      </h2>{" "}
                      <span className="qodef-st-text">
                        {" "}
                        Here is a glimpse of some projects we have worked on.{" "}
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- //featured projects heading and description div --> */}
      {/* <!-- gallery section --> */}
      <div className="qodef-row-grid-section-wrapper ">
        <div className="qodef-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_custom_gallery">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="vc_empty_space" style={{ height: 32 }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                  <div className="qodef-portfolio-list-holder qodef-grid-list qodef-pl-gallery qodef-three-columns qodef-small-space qodef-pl-standard-lift qodef-pl-pag-no-pagination">
                    <div className="qodef-pl-inner qodef-outer-space clearfix">
                      
                      
                      {images.map((image, idx) => (
                        <article key={idx} className="qodef-pl-item qodef-item-space  post-6178 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-commercial portfolio-tag-homepage-gallery">
                          <div className="qodef-pl-item-inner">
                            <div className="qodef-pli-image">
                              {" "}
                              <img
                                width="550"
                                height="550"
                                // src="images/uploads/ArchFEATUREDPROJECTS-1-1.jpg"
                                src={`${Config.IMAGES_BASE}/architecture/Featured/${image.image}`}
      
                                className="attachment-dessau_select_square size-dessau_select_square wp-post-image"
                                alt="The Opus Office"
                                loading="lazy"
                              />{" "}
                            </div>
                            <div className="qodef-pli-text-holder">
                              <div className="qodef-pli-text-wrapper">
                                <div className="qodef-pli-text">
                                  {/* <!--<h6 itemprop="name" className="qodef-pli-title entry-title" style="text-transform: uppercase"> The Opus Offices, DBX, UAE	</h6>--> */}
                                  {/* <!--<div className="qodef-pli-category-holder"> <a itemprop="url" className="qodef-pli-category" href="#">Commercial</a> </div>--> */}
                                </div>
                              </div>
                            </div>
                            <a
                              itemprop="url"
                              className="qodef-pli-link qodef-block-drag-link"
                              href="#"
                              target="_self"
                            ></a>
                          </div>
                        </article>
                      ))}


                    </div>
                  </div>

                  <div className="vc_empty_space" style={{ height: 32 }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- //gallery section --> */}
        {/* <!-- Empty space section --> */}
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
