import React, { useEffect, useState, useContext } from "react";
import { MiniCart } from "./MiniCart";
import "../libs/Nm_Events";
import { UserCtx, ACTION } from "../libs/UserCtx";
import { Link } from "react-router-dom";

export const FileHeader = () => {
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [estimates, setEstimates] = useState(
    JSON.parse(localStorage.getItem("estimates")) || []
  );
  const { user, dispatch } = useContext(UserCtx);

  useEffect(() => {
    window.NM_EVENTS.subscribe("USER_LOGGED_IN", function () {
      setEmail(localStorage.getItem("email") || "");
    });

    window.NM_EVENTS.subscribe("SAVE_ESTIMATES", function () {
      const _allEstimates = JSON.parse(localStorage.getItem("estimates")) || [];
      setEstimates(_allEstimates);
      console.log("_allEstimates", _allEstimates);
    });
    if (email != "") {
      dispatch({ type: ACTION.LOGIN, payload: email });
    }
  }, []);

  const HeaderContainer = ({ children }) => {
    const logout = () => {
      dispatch({ type: ACTION.LOGOUT });
      localStorage.removeItem("email");
      window.location.href = "/";
    };
    return (
      <div className="qodef-vertical-align-containers">
        <div className="qodef-position-left">
          <div className="qodef-position-left-inner">
            <div className="qodef-logo-wrapper">
              <Link to="/" style={{top:16}}>
                {" "}
                <img
                    itemProp="image"
                    className="qodef-normal-logo"
                    src="/images/logos/full_trimmed_transparent_black.png"
                    alt="logo"
                    style={{width:150}}
                /> {" "}
                <img
                    itemProp="image"
                    className="qodef-dark-logo"
                    src="/images/logos/full_trimmed_transparent_black.png"
                    alt="dark logo"
                    style={{width:150}}
                /> {" "}
              </Link>
            </div>
          </div>
        </div>
        <div className="qodef-position-center">
          <div className="qodef-position-center-inner">
            <nav className="qodef-main-menu qodef-drop-down qodef-default-nav">
              <ul id="menu-main-menu-navigation" className="clearfix">
                <li
                  id="nav-menu-item-5469"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-20 current_page_item qodef-active-item narrow"
                >
                  <a href="/" className=" current ">
                    <span className="item_outer">
                      <span className="item_text">Home</span>
                    </span>
                  </a>
                </li>
                <li
                  id="nav-menu-item-5514"
                  className="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                >
                  <a href="/#work" className="">
                    <span className="item_outer">
                      <span className="item_text">Projects</span>
                    </span>
                  </a>
                </li>
                <li style={{display:"none"}}
                  id="l_solutions"
                  className="dropdown menu-item menu-item-type-post_type menu-item-object-page narrow"
                >
                  <a
                    className="limenu dropdown-toggle"
                    data-toggle="dropdown"
                    href=""
                    aria-expanded="false"
                  >
                    {" "}
                    <span className="item_outer">
                      <span className="item_text">
                        Services<span className="caret"></span>
                      </span>
                    </span>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="#">Structural Drawings</a>
                    </li>
                    <li>
                      <a href="#">Working Drawings</a>
                    </li>
                    <li>
                      <a href="#">Electrical Drawings</a>
                    </li>
                    <li>
                      <a href="#">Plumbing Drawings</a>
                    </li>
                  </ul>
                </li>
                <li style={{display:"none"}}
                  id="nav-menu-item-5833"
                  className="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                >
                  <a href="#" className="">
                    <span className="item_outer">
                      <span className="item_text">Blog</span>
                    </span>
                  </a>
                </li>
                <li style={{display:"none"}}
                  id="nav-menu-item-5483"
                  className="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                >
                  <a href="#" className="">
                    <span className="item_outer">
                      <span className="item_text">Contact</span>
                    </span>
                  </a>
                </li>
                {/* {email == "" ? ( */}
                {!user || !user.email ? (
                  <>
                    <li style={{display:"none"}}
                      id="nav-menu-item-5483"
                      className="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                    >
                      <a href="#" className="">
                        <span className="item_outer">
                          <span className="item_text">Login</span>
                        </span>
                      </a>
                    </li>
                    <li style={{display:"none"}}
                      id="nav-menu-item-5483"
                      className="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                    >
                      <a href="#" className="">
                        <span className="item_outer">
                          <span className="item_text">New Account</span>
                        </span>
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li style={{display:"none"}}
                      id="nav-menu-item-5483"
                      className="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                    >
                      <a href="/estimates" className="">
                        <span className="item_outer">
                          <span className="cart-counter">
                            {estimates.length}
                          </span>
                          <span className="item_text">Estimates</span>
                        </span>
                      </a>
                    </li>

                    <li style={{display:"none"}}
                      id="nav-menu-item"
                      className="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                    >
                      <a
                        className="limenu dropdown-toggle"
                        data-toggle="dropdown"
                        href=""
                        aria-expanded="false"
                      >
                        <span className="item_outer">
                          <span className="item_text">
                            <i className="fas fa-user-alt"></i>
                            <span className="caret"></span>
                          </span>
                        </span>
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a href="#">My Account</a>
                        </li>
                        <li>
                          <a href="#">Settings</a>
                        </li>
                        <li>
                          <a href="#">My Orders</a>
                        </li>
                        <li>
                          <a href="#" onClick={() => logout()}>
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                    {/**<MiniCart /> **/}
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
        <div className="qodef-position-right">
          <div className="qodef-position-right-inner">{children}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <header className="qodef-page-header">
        <div className="qodef-menu-area qodef-menu-center">
          <HeaderContainer />
        </div>
        <div id="js-sticky-header" className="qodef-sticky-header">
          <div className="qodef-sticky-holder qodef-menu-center">
            <HeaderContainer>
              <a className="qodef-side-menu-button-opener qodef-icon-has-hover qodef-side-menu-button-opener-predefined">
                {" "}
                <span className="qodef-side-menu-icon">
                  <span className="qodef-hm-lines">
                    <span className="qodef-hm-line qodef-line-1"></span>{" "}
                    <span className="qodef-hm-line qodef-line-2"></span>{" "}
                    <span className="qodef-hm-line qodef-line-3"></span>{" "}
                  </span>
                </span>
              </a>
            </HeaderContainer>
          </div>
        </div>
      </header>
    </div>
  );
};
