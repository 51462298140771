import React, { useState, useEffect } from "react";
import { Api } from "../libs/Api";
import { useNavigate } from "react-router-dom";
import { LoginModal } from "../components/LoginModal";

export const LoginPg = () => {
  
  return (
    <div
      id="site-page"
      className="site-page interior-page hfeed site"
      style={{ backgroundColor: "#fff" }}
    >
      <div
        id="site-page__wrapper"
        className="site-page__wrapper wbcontainer--fluid"
      >
        <div id="site-main" className="site-main">
          <div role="main" className="main-content ">
            <div className="site-content wbcol--12">
              <div className="site-content__inner">
                <div
                  role="article"
                  id="post-43611"
                  className="post-43611 page type-page status-publish hentry"
                >
                  <div className="entry__content">
                    <div
                      className="wbcontainer wp-block-group alignfull has-background"
                      style={{
                        background: "url(images/login-background.jpg)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div className="row">
                        <div
                          id="login_card"
                          className="col-md-6 col-md-offset-3"
                        >
                          <LoginModal />
                        </div>
                      </div>
                      <div style={{ height: 100 }}></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- .site-content__inner --> */}
            </div>
            {/* <!-- .site-content --> */}
          </div>
          {/* <!-- .main-content --> */}
        </div>
        {/* <!-- .site-main --> */}
      </div>
      {/* <!-- END: site-page__wrapper --> */}
    </div>
  );
};
