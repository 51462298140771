import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../libs/Nm_Events";
import { UserCtx } from "../libs/UserCtx";
import { FileUpload } from "./FileUpload";


export const DesignCalculator = ({
  id,
  title,
  color,
  serviceEstimate,
  setServiceEstimate,
  total,
  setTotal,
  email,
  rate,
}) => {
  const [homeConfig, setHomeConfig] = useState("1BHK");  //just a label
  const [builtupArea, setBuiltupArea] = useState(0);
  const [plotArea, setPlotArea] = useState(0);
  const [dddViews, setDddViews] = useState(true);
  const [walkthrough, setWalkthrough] = useState(false);
  const {user, dispatch} = useContext(UserCtx);
  
  let navigate = useNavigate();

  const saveEstimate = () => {
    let _estimate = {
      category : window.location.pathname.replace("/",""),
      service : title,
      estimates : serviceEstimate,
      plotArea: plotArea,
      builtupArea: builtupArea,
      total : total,
    };
    let _estimateWalkthrough = {
      category : window.location.pathname.replace("/",""),
      service : "Walkthrough",
      estimates : serviceEstimate,
      plotArea: plotArea,
      builtupArea: builtupArea,
      total : total,
    };

    let _allEstimates = JSON.parse(localStorage.getItem("estimates")) || [];
    let found = false;
    let foundWalkthrough = false;
    for (let i = 0; i < _allEstimates.length ; i++){
      let ele = _allEstimates[i];
      if (ele.service == title ){
        ele = _estimate;
        found = true;
      }
      if (ele.service == "Walkthrough" ){
        ele = _estimateWalkthrough;
        foundWalkthrough = true;
      }
    }
    if (!found){
      _allEstimates.push(_estimate);
    }
    if (!foundWalkthrough){
      _allEstimates.push(_estimateWalkthrough);
    }

    localStorage.setItem("estimates",JSON.stringify(_allEstimates))
    window.NM_EVENTS.publish("SAVE_ESTIMATES");
  }

  // const [width, setWidth] = useState(0);
  // const [length, setLength] = useState(0);
  // const [nFloors, setNFloors] = useState(1);
  const [carpetArea, setCarpetArea] = useState(0);
  const [floors, setFloors] = useState([{ name: "Floor 1", area: 0 }]);


  function calculateAreas(inCarpetArea , inFloors){
    setPlotArea (Number(inCarpetArea).toFixed(1));
    let _builtUpArea = inFloors.reduce((sum, ele)=> sum + Number(ele.area) ,0);
    _builtUpArea = Number(_builtUpArea).toFixed(1) - (-Number(inCarpetArea).toFixed(1));  //+ is not working for some reason
    console.log('total build up area',_builtUpArea);
    setBuiltupArea(_builtUpArea);
    return _builtUpArea;
  }

  
  const setMyServiceEstimate = (value) => {
    let _serviceEstimate = JSON.parse(JSON.stringify(serviceEstimate));
    _serviceEstimate[title] = value;
    setServiceEstimate(_serviceEstimate);
    let _total = Object.keys(_serviceEstimate).reduce((sum, key)=>sum+_serviceEstimate[key], 0);
    setTotal(_total);
  };

  const setWalkthroughEstimate = (value) => {
    let _serviceEstimate = JSON.parse(JSON.stringify(serviceEstimate));
    _serviceEstimate["Walkthrough"] = value;
    setServiceEstimate(_serviceEstimate);
    let _total = Object.keys(_serviceEstimate).reduce((sum, key)=>sum+_serviceEstimate[key], 0);
    setTotal(_total);
  };

  const changeCarpetArea = (value) => {
    setCarpetArea(value);

    let _builtUpArea = calculateAreas(value , floors)
    setMyServiceEstimate(rate[title] * _builtUpArea);
  };
  
  const changeWalkthrough = (isOptedFor) => {
    setWalkthrough(isOptedFor);
    isOptedFor?setWalkthroughEstimate(rate["Walkthrough"]):setWalkthroughEstimate(0);
  }


  const addFloor = () => {
    let _floors = JSON.parse(JSON.stringify(floors));
    _floors.push({ name: `Floor ${_floors.length + 1}`, area: 0 });
    setFloors(_floors);
  };

  const editFloor = (idx) => {
    let _floors = JSON.parse(JSON.stringify(floors));
    _floors[idx].isEditing = true;
    setFloors(_floors);
  };
  const saveFloor = async(idx, params) => {
    let _floors = JSON.parse(JSON.stringify(floors));
    for (let key in params){
      _floors[idx][key] = params[key];  
    }
    // _floors[idx].name = document.getElementById(`floor${idx}Id`).innerText;;
    _floors[idx].isEditing = false;
    setFloors(_floors);
    // totalBuiltUpArea(_floors)
    let _builtUpArea = calculateAreas(carpetArea , _floors)
    setMyServiceEstimate(rate[title] * _builtUpArea);
  };

  const deleteFloor = (idx) => {
    floorToDelete = idx;
    
  };

  //using API for id get the rate[title]

  let floorToDelete = 1;
  window.NM_CALLBACKS.confirmDelete = () =>{
    console.log ("window Confirm callback");
    let _floors = JSON.parse(JSON.stringify(floors));
    _floors.splice(floorToDelete,1);
    setFloors(_floors);
  }
  useEffect(async () => {
    console.log("useEffect Advanced Calc");
    // effect
    let _total = Object.keys(serviceEstimate).reduce((sum, key)=>sum + serviceEstimate[key], 0);
    setTotal(_total);
    

    return () => {
      // cleanup
    };
  }, []);


  
  return (
      <>
        <div className="col-sm-8 col-xs-12 col-steps" style={{ marginTop: 35 }}>
          <div className="card">
            <h5>{title} Calculator</h5>
            <hr />
            <h5>Step 1: Home Configuration</h5>
            <div className="form-group mb-25">
              <select
                className="form-control select_cal"
                onChange={(e) => setHomeConfig(e.target.value)}
              >
                <option value="1BHK">1BHK </option>
                <option value="2BHK">2BHK </option>
                <option value="3BHK">3BHK</option>
                <option value="4BHK">4BHK</option>
                <option value="Ground Floor">Ground Floor </option>
                <option value="Multiple Floors">Multiple Floors </option>
                <option value="Duplex">Duplex </option>
                <option value="Villa">Villa</option>
              </select>
            </div>
          </div>

          


          <div>
      <div className="a-cal card">
        <h5>Step 2: Area</h5>
        <hr />
        <div className="row">
          <div className="col-xs-6 col-sm-6">
            <label className="radio-inline">
              <label htmlFor="usr">Carpet Area</label>
              <input
                type="number"
                name="carpetArea"
                value={carpetArea}
                onChange={(e) => changeCarpetArea(e.target.value)}
              />
              &nbsp; <span className="ipunits">Ft.</span>{" "}
            </label>
          </div>
          <div className="col-xs-6 col-sm-6">
          </div>

          {floors.map((floor, idx) => (
            <div className="div-floor" id="2dfloors" key={idx}>
              <div className="col-xs-12 col-sm-12 col-floor" id="2dfloor1">
                <label className="radio-inline">
                  <label>
                    <span id = {`floor${idx}Id`} className="flrname" contentEditable={floor.isEditing }>{floor.name}</span> &nbsp;{" "}
                    <i
                      className={`fas ${floor.isEditing?"fa-check":"fa-pen"}`}
                      onClick={() => {
                        if (floor.isEditing)
                          saveFloor(idx, {name : document.getElementById(`floor${idx}Id`).innerText});
                        else 
                          editFloor(idx);
                      }}
                    ></i>
                    <span className="dashline"> | </span>{" "}
                    <i
                      data-toggle="modal"
                      data-target="#myModal"
                      className="del_floor far fa-times-circle"
                      onClick={() => deleteFloor(idx)}
                    ></i>
                  </label>
                  <input type="number" name="floor" 
                  value={floor.area}
                  onChange={(e) => saveFloor(idx,{area: (e.target.value)})}
                  />
                  &nbsp; <span className="ipunits">Sqft.</span>{" "}
                </label>
              </div>
            </div>
          ))}

          <div className="col-xs-12 ptb-10" onClick={() => addFloor()}>
            <div className="add_floor radio-inline">
              {" "}
              <i className="fas fa-plus-circle"></i>{" "}
              <span className="font_bold">Add Floor</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="card">
          <h5>Step 3: Visulization</h5>
          <hr />
          <div className="container-fluid" style={{padding: "0 10px"}}>
            <div className="col-xs-12 col-sm-6">
              <label className="radio-inline custom-checkbox">
                <input type="checkbox" name="myview" checked={dddViews} 
                  onChange={(e)=>{
                    if (true) {
                      alert("3D Views cannot be disabled");
                      return;//dont change if walkthru is checked 
                    }  
                    // setDddViews(e.target.checked);
                    }}/>
                <span className="checkmark"></span>
                3D Views
                <br />{" "}
                <span style={{whiteSpace: "nowrap",fontSize: 13}}>
                  {" "}
                  (Each room 2 views){" "}
                </span>
              </label>
            </div>
            <div className="col-xs-12 col-sm-6">
              <label className="radio-inline custom-checkbox">
                <input type="checkbox" name="walkthrough"  checked={walkthrough} 
                onChange={(e)=>{
                  changeWalkthrough(e.target.checked);
                  // setDddViews(e.target.checked);
                }}/>
                <span className="checkmark"></span>
                Walkthrough{" "}
              </label>
            </div>
          </div>
        </div>
      <FileUpload title = "Step 4: Upload"/>
      
    </div>







          
        </div>
        <div className="col-sm-4 col-xs-12 calc1" style={{ marginTop: 35 }}>
          <div className="card">
            <h5>Estimated Price</h5>
            <hr />
            <p>
              Carpet area:{" "}
              <span className="plot_area f-right">{carpetArea} Sqft.</span>
            </p>
            <p>
              Total Carpet area:{" "}
              <span className="gf_area f-right">{builtupArea} Sqft.</span>
            </p>
            <p className="purple-color font_bold mb-20">
              {title}:
              <span className="f-right">
                <span className="Rs">₹</span>
                <span className="2d_cost">
                  {/* {email == ""? */}
                  {!user || !user.email ? 
                    <i className="fa fa-lock" aria-hidden="true">***</i>
                    :
                    serviceEstimate[title]
                  }
                </span>
              </span>
            </p>
            {/* {otherServices.filter((svc)=>(svc.service != title)&&(svc.estimate != 0)).map((svc)=>( */}
            {Object.keys(serviceEstimate)
              .filter((svc) => svc != title && serviceEstimate[svc] != 0)
              .map((svc) => (
                <p key={svc.key} className="purple-color font_bold mb-20">
                  {svc}:
                  <span className="f-right">
                    <span className="Rs">₹</span>
                    <span className="2d_cost">
                      {/* {email == ""? */}
                      {!user || !user.email ? 
                      <i className="fa fa-lock" aria-hidden="true">***</i>
                      :
                      serviceEstimate[svc]}
                    </span>
                  </span>
                </p>
              ))}
            <span className="cost-append"></span>
            <p className="purple-color font_bold mb-20">
              Total:{" "}
              <span className="f-right">
                <span className="Rs">₹</span>{" "}
                <span className="sub_cost">
                {!user || !user.email ? 
                // {email == ""?
                  <i className="fa fa-lock" aria-hidden="true">***</i>
                  :
                total} 
                </span>
              </span>
            </p>
            {!user || !user.email ? 
            // {email == ""?
            <>
            <div className="text-center">
            <button
                className="sendEstimate custom-btn purple_bg font_bold"
                style={{ background: color }}
                // onClick={()=>doEstimate()}
                data-toggle="modal" data-target="#myLoginModal"
              >
                <i className="fa fa-lock-open" aria-hidden="true"></i>{" "}
                &nbsp;Estimate
              </button>
            </div>
            <hr className="border_theme" />
            </>
            :
            <>
            <div className="text-center">
            <button
                className="sendEstimate custom-btn purple_bg font_bold"
                style={{ background: color }}
                onClick={()=>saveEstimate()}
              >
                <i className="fa fa-save" aria-hidden="true"></i>{" "}
                &nbsp; Save Estimate
              </button>
              <hr className="border_theme" />
              <button
                className="custom-btn2 font_bold  mrl-10 w170 mb-10"
                style={{ color: color, border: `1px solid ${color}` }}
              >
                <i className="fa fa-shopping-cart" aria-hidden="true"></i> Add to Cart
              </button>
              <button
                className="custom-btn2 font_bold  mrl-10 w170"
                style={{ color: color, border: `1px solid ${color}` }}
              >
                <i className="fas fa-mouse-pointer"></i> Buy Now
              </button>
            </div>
            </>
            }
          </div>
        </div>
      </>
  );
};
