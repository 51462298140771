import React, { useState,useEffect } from "react";
import { Banners } from "../components/Banners";
import { FullPackage } from "../components/FullPackage";
import { TwoBoxRow } from "../components/TwoBoxRow";
import { Calculator } from "../components/Calculator";
import { ConfirmDeleteDlg } from "../components/ConfirmDeleteDlg";
import { LoginModal } from "../components/LoginModal";
import "../libs/Nm_Events";
import {Api} from "../libs/Api";
import {Pg} from "../Pg"
import {Config} from "../Config"



export const CivilPg = ({ category, color }) => {
  const [banners, setBanners] = useState([]);
  const [images, setImages] = useState([]);

  const _servicesZero = Pg[category].calculators.reduce((ac,a)=>({...ac,[a]:0}),{});
  // _servicesZero = {
  //   "Structural Drawing": 0,
  //   "Working Drawing": 0,
  //   "Electrical Drawing": 0,
  //   "Plumbing Drawing": 0,
  // }
  const [serviceEstimate, setServiceEstimate] = useState(_servicesZero);
  const [rate, setRate] = useState(_servicesZero);
  const [total, setTotal] = useState(0);
  const [email,setEmail] = useState(localStorage.getItem("email") || "") ;

  useEffect(async()=>{
    const imgResponse = await Api.get_all("images","category=civil");
    console.log("response civil images",imgResponse);

    setImages(imgResponse.data
      .filter((ele)=>ele.service != "banners")
    );
    setBanners(imgResponse.data.filter((ele)=>ele.service == "banners"));
    window.NM_EVENTS.subscribe("USER_LOGGED_IN", ()=>{
      setEmail(localStorage.getItem("email") || "");
    });
    const response = await Api.get_all("service_rates","category=civil");
    console.log('rate:resp',response);
    let _rate = {};
    response.data.forEach((ele) => {
      _rate[ele.service] = ele.rate;
    });
    console.log("useEffect Civil, rate set to",_rate);
    setRate(_rate);

  },[])

  return (
    <>
      <div className="qodef-content" style={{ marginTop: -140 }}>
        <div className="qodef-content-inner">
          <div className="qodef-full-width">
            <div className="qodef-full-width-inner">
              <div className="qodef-grid-row">
                <div className="qodef-page-content-holder qodef-grid-col-12">
                  <Banners category={category} banners={banners}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="vc_row wpb_row vc_row-fluid vc_custom_twobox">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">

              {/* {Pg[category].service_text.map((svc_txt, idx)=>( */}
              {images.map((image, idx)=>(
                <TwoBoxRow
                  key={`twoboxrow${idx}`}
                  id={`twoboxrow${idx}`}
                  title={`${image.service}`}
                  // text={Pg[category].service_text[idx]}
                  text={image.title}
                  textPosition= { idx%2 == 0 ? "left" : "right"}
                  // image={`images/services/${category}/${(Pg[category].calculators[idx]).replace(" ","_")}.jpg`}
                  image={`"${Config.IMAGES_BASE}/${image.category}/${image.service}/${image.image}"`}
                  // image={`"${image.image_url}"`}
                  color={color}
                >
                  <Calculator
                    key={`calc${idx}`}
                    id={`calc${idx}`}
                    title={`${Pg[category].calculators[idx]}`}
                    color={color}
                    serviceEstimate={serviceEstimate}
                    setServiceEstimate={setServiceEstimate}
                    total={total}
                    setTotal={setTotal}
                    email={email}
                    rate = {rate}
                    />
                </TwoBoxRow>
              ))}

              <FullPackage color={color} />
            </div>
          </div>
        </div>
      </div>
      
      <ConfirmDeleteDlg color={color} />

      <div id="myLoginModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content-->  */}
          <div className="modal-content" >
            <div className="modal-body" style={{ padding: "15px 25px" }}>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>

              {/* <div> 
                <p className="login_title"><b>Already have an account?<br/>
                Please login here</b></p> <br/>
                <form action="" method="post">
                    <label>E-mail</label>
                    <input type="text" name="user_login"/> 
                    <label>Password:</label>
                    <input type="password" name="user_pass"/> 
                <p><small>Forgot your password? Click <a style={{color: "#4296c7"}} href="#">here</a></small></p> 
                <button className="btn btn-primary" type="submit">Log in</button>
                </form>
                </div> */}

              <LoginModal />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
