import React, {useEffect, useState, useContext, useRef} from "react";
import {Config} from "../Config"
import {Api} from "../libs/Api";
import { UserCtx,ACTION} from '../libs/UserCtx';
import {Link} from "react-router-dom";


export const HomePg = () => {
  const context = useContext(UserCtx);
  const {state,dispatch} = context;
  const {serverData,loading,loadedServerData} = state;

  const [banners, setBanners] = useState(()=>{
    if(loadedServerData && Array.isArray(serverData)){
      return serverData.filter((ele)=>ele.service === "banners")
    }
    return [];
  });
  const [images, setImages] = useState(()=> {
    if(loadedServerData && Array.isArray(serverData)){
      return serverData;
    }
    return [];
  });

  const promoContentRef=useRef();

  useEffect(()=>{
    if( promoContentRef.current){
      const timeout = setTimeout(()=>{
        promoContentRef.current.style.width = '100%';
        promoContentRef.current.style.transform = 'translateX(0px)';
      },2000)
     return ()=>{
        clearTimeout(timeout)
     }
    }
  },[promoContentRef])

  useEffect( async() => {
    const script = document.createElement('script');
    if(!loadedServerData){
      dispatch({type:ACTION.LOADING, payload:true});
      const response = await Api.get_all("images","category=home");
      console.log("response home images",response);

      setImages(response.data);
      setBanners(response.data.filter((ele)=>ele.service === "banners"));

      dispatch({type:ACTION.INIT_DATA, payload:response.data});
    }
    script.src = "home/js/script.js";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [loadedServerData]);

  return (
    <>
    {/* <script type='text/javascript' src='home/js/script.js'></script> */}

    <link href="home/css/style.css" rel='stylesheet' type='text/css' />

    <div className="m-js m-no-touch m-rgba m-opacity m-cssanimations m-cssgradients m-csstransforms m-csstransitions m-video m-svg m-inlinesvg d-no-mobile d-no-ipad d-no-iphone d-no-ios d-no-ios7 d-no-phone">
      {/* <!-- Page --> */}
      <div className={`page ${loading? ' page_loading':''}`} id="root">
        {/* <!-- UI --> */}
        <a className="ui-logo " href="/"></a>{" "}
        <span className="ui-menu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="40"
            viewBox="0 0 80 40"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#fff"
              d="M0 40v-40h80v40h-80zm31.014-24.986h-3.587l-1.315 4.803c-.18.691-.599 2.501-.599 2.501h-.029s-.419-1.81-.613-2.501l-1.3-4.803h-3.559v10.972h2.377v-5.509c0-.445-.045-2.21-.045-2.21h.03s.374 1.657.493 2.057l1.524 5.662h2.183l1.54-5.678c.119-.399.493-2.041.493-2.041h.029s-.044 1.75-.044 2.194v5.524h2.421v-10.971zm8.984 8.672h-5.408v-2.349h4.562v-2.118h-4.562v-1.964h5.321v-2.241h-7.901v10.972h7.988v-2.3zm10.014-8.672h-2.548v5.203c0 .383.029 1.703.029 1.703h-.029l-.722-1.396-3.122-5.509h-2.621v10.972h2.548v-5.11c0-.384-.029-1.795-.029-1.795h.029s.574 1.182.751 1.473l3.093 5.432h2.621v-10.973zm9.989 0h-2.661v7.029c0 1.057-.586 1.811-1.819 1.811-1.308 0-1.849-.709-1.849-1.765v-7.075h-2.661v6.984c0 2.52 1.504 3.998 4.51 3.998 2.992 0 4.48-1.509 4.48-3.998v-6.984z"
            />
          </svg>
        </span>
        {/* <!-- NAV Drop down styling     --> */}
        <nav className="menu">
          {" "}
          <i className="menu__close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#fff"
                d="M24 22.568l-1.433 1.432-10.567-10.568-10.568 10.568-1.432-1.432 10.568-10.568-10.568-10.568 1.432-1.432 10.568 10.567 10.567-10.567 1.433 1.432-10.568 10.568 10.568 10.568z"
              />
            </svg>
          </i>
          <menu className="menu__content">
            {" "}
            <a className="menu__item menu__item_work" href="#work">
              Projects
            </a>{" "}
            <Link className="menu__item menu__item_clients" to="/interior">
              Interior
            </Link>{" "}
            <Link className="menu__item menu__item_about" to="/civil">
              Civil
            </Link>
            <li className="dropdown menu__item">
              <a
                className="limenu dropdown-toggle"
                data-toggle="dropdown"
                href="javascript:void(0);"
                aria-expanded="false"
              >
                {" "}
                <span className="item_outer">
                  <span className="item_text" title={"Coming Soon"}>
                    Construction { /** <span className="caret"></span> **/ }
                  </span>
                </span>
              </a>
              {
                /**
              <ul className="dropdown-menu">
                <li>
                  <a href="civil">Structural Drawings</a>
                </li>
                <li>
                  <a href="civil">Working Drawings</a>
                </li>
                <li>
                  <a href="civil">Electrical</a>
                </li>
                <li>
                  <a href="civil">Plumbing Drawings</a>
                </li>
              </ul>
                 **/
              }
            </li>
            <li className="dropdown menu__item">
              <Link
                className="limenu dropdown-toggle"
                data-toggle="dropdown"
                to="/architecture"
                aria-expanded="false"
              >
                {" "}
                <span className="item_outer">
                  <span className="item_text">
                    Architecture<span className="caret"></span>
                  </span>
                </span>
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/architecture">2D Floor Plan</Link>
                </li>
                <li>
                  <Link to="/architecture">3D Floor Plan</Link>
                </li>
                <li>
                  <Link to="/architecture">3D Elevation</Link>
                </li>
                <li>
                  <Link to="/architecture">Designing</Link>
                </li>
              </ul>
            </li>{" "}
            <a className="menu__item menu__item_contact" href="/about">
              About
            </a>{" "}
          </menu>
          <footer className="menu__footer">
            <form
              className="menu__search"
              action="https://www.incociar.com/search"
              method="get"
            >
              <input
                className="menu__search__field"
                type="text"
                placeholder="Search"
                name="q"
              />
              <button className="menu__search__button" type="submit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="30"
                  viewBox="0 0 34 30"
                >
                  <path
                    fill="#FFF"
                    d="M24.082 11.248l-1.418 1.41 6.516 6.342h-25.18v-17.88h-2v19.88h27.137l-6.465 6.574 1.402 1.426 8.926-9z"
                  />
                </svg>
              </button>
            </form>
            <div className="menu__networks">
              {" "}
              <a
                  className="menu__networks__item"
                  href="https://www.linkedin.com/company/incociar/"
                  target="_blank"
              >
                LinkedIn
              </a>{" "}
              <a
                className="menu__networks__item"
                href="https://www.facebook.com/Incociar"
                target="_blank"
              >
                Facebook
              </a>{" "}
              <a
                className="menu__networks__item"
                href="https://twitter.com/incociar"
                target="_blank"
              >
                Twitter
              </a>{" "}
              <a
                className="menu__networks__item"
                href="https://instagram.com/incociar"
                target="_blank"
              >
                Instagram
              </a>{" "}
              <a
                className="menu__networks__item"
                href="https://youtube.com/@incociar4703"
                target="_blank"
              >
                Youtube
              </a>{" "}
              <a
                  className="menu__networks__item"
                  href="https://pin.it/2hWW8Cu"
                  target="_blank"
              >
                Pinterest
              </a>{" "}
            </div>
          </footer>
        </nav>
        {/* <!--/UI -->
		<!-- Promo --> */}
        <section className="promo">
          {/* <!-- content --> */}
          <div className="promo__content" ref={promoContentRef}>

          {banners.map((banner, idx)=>(
            <Link key={idx} className="promo__item promo__item_light" to={"/about"}>
              {" "}
              <span className="promo__item__bg">
                  <i
                      className="promo__item__bg__picture"
                      style={{
                        backgroundImage:
                            `url(${Config.IMAGES_BASE}/home/banners/${banner.image})`,
                      }}
                  ></i>
                </span>
            </Link>
            ))}




            {/* <!-- / --> */}
          </div>
          {/* <!-- next --> */}
          <div className="promo__next"></div>
          {/* <!-- mouse --> */}
          <div className="ui-mouse">
            {" "}
            <i className="ui-mouse__wheel"></i>{" "}
          </div>
          {/* <!-- / --> */}
        </section>
        {/* <!--/Promo --> */}
        {/* <!-- Works --> */}
        <section className="works" id="work">
          <article className="works__group works__group_simple">
            <div
              className="works__item works__item_1 works__item_graphic"
              data-category="category_2"
              data-color="#b9bbbe"
              data-category_name="tabletop"
            >
              <Link to="/interior">
                <i
                  className="works__item__picture works__item__picture_right-bottom"
                  style={{
                    backgroundImage:
                    `url(${Config.IMAGES_BASE}/home/interior/${(images.filter((ele)=>ele.service==="interior")[0] || {image:''}).image})`,
                  }}
                ></i>
                <div className="works__item__content">
                  <div className="works__item__title">Interior</div>
                </div>
              </Link>
            </div>
            <div
              className="works__item works__item_2 works__item_concept-exhibition"
              data-category="category_10"
              data-color="#f15b3a"
              data-category_name="graphic"
            >
              <Link to={"/"}>
                <i
                  className="works__item__picture works__item__picture_right-center"
                  style={{
                    backgroundImage:
                    `url(${Config.IMAGES_BASE}/home/construction/${(images.filter((ele)=>ele.service==="construction")[0] || {image:''}).image})`,
                  }}
                ></i>{" "}
                <i className="works__item__picture"></i>
                <div className="works__item__content">
                  <div className="works__item__title" title={"Coming Soon"}>Construction</div>
                </div>
              </Link>
            </div>
            <div
              className="works__item works__item_3 works__item_media"
              data-category="category_9"
              data-color="#fdd025"
              data-category_name="fashion"
            >
              <Link to="/civil">
                <i
                  className="works__item__picture works__item__picture_right-center"
                  style={{
                    backgroundImage: 
                    `url(${Config.IMAGES_BASE}/home/civil/${(images.filter((ele)=>ele.service==="civil")[0] || {image:''}).image})`,
                  }}
                ></i>
                <div className="works__item__content">
                  <div className="works__item__title">Civil</div>
                </div>
              </Link>
            </div>
            <div
              className="works__item works__item_4 works__item_art"
              data-category="category_11"
              data-color="#0cb8f2"
              data-category_name="art_exhibition"
            >
              <Link to="/architecture">
                <i
                  className="works__item__picture works__item__picture_right-bottom"
                  style={{
                    backgroundImage:
                    `url(${Config.IMAGES_BASE}/home/architecture/${(images.filter((ele)=>ele.service==="architecture")[0] || {image:''}).image})`,
                  }}
                ></i>
                <div className="works__item__content">
                  <div className="works__item__title">Architecture</div>
                </div>
              </Link>
            </div>
          </article>
          <article
            className="works__group works__group_simple"
            style={{ minHeight: 400, maxHeight: 480 }}
          >
            <div
              className="works__item works__item_1 works__item_graphic"
              style={{ height: "100%" }}
              data-category="category_2"
              data-color="#a2c7ff"
              data-category_name="tabletop"
            >
              <Link to="/painting">
                <i
                  className="works__item__picture works__item__picture_right-bottom"
                  style={{
                    backgroundImage:
                      `url(${Config.IMAGES_BASE}/home/painting/${(images.filter((ele)=>ele.service==="painting")[0] || {image:''}).image})`,
                  }}
                ></i>
                <div className="works__item__content">
                  <div className="works__item__title"> Painting </div>
                </div>
              </Link>
            </div>
            <div
              className="works__item works__item_2 works__item_concept-exhibition"
              style={{ height: "100%" }}
              data-category="category_10"
              data-color="#c682d9"
              data-category_name="graphic"
            >
              <a href="http://wallpap.in">
                <i
                  className="works__item__picture works__item__picture_right-center"
                  style={{
                    backgroundImage:
                      `url(${Config.IMAGES_BASE}/home/wallpapers/${(images.filter((ele)=>ele.service==="wallpapers")[0] || {image:''}).image})`,
                  }}
                ></i>{" "}
                <i className="works__item__picture"></i>
                <div className="works__item__content">
                  <div className="works__item__title">Wallpapers</div>
                </div>
              </a>
            </div>
          </article>
        </section>
        {/* <!--/Works --> */}
        <script className="ui-help__template" type="text/x-handlebars-template">
          <div className="ui-help">
            <div className="ui-help__mouse">
              {" "}
              <i className="ui-help__mouse__wheel"></i>{" "}
            </div>
          </div>
        </script>
      </div>
      {/* <!--/Page --> */}
      {/* <!-- JS --> */}
      <script src="home/js/script.js"></script>
    </div>
    </>
  );
};
