import React, { useEffect, useState, useContext } from "react";
import { AdvanceCalculator } from "./AdvanceCalculator";
import { QuickCalculator } from "./QuickCalculator";
import { useNavigate } from "react-router-dom";
import "../libs/Nm_Events";
import { UserCtx } from "../libs/UserCtx";


export const Calculator = ({
  id,
  title,
  color,
  serviceEstimate,
  setServiceEstimate,
  total,
  setTotal,
  email,
  rate,
}) => {
  const [flavor, setFlavor] = useState("quick");
  const [builtupArea, setBuiltupArea] = useState(0);
  const [plotArea, setPlotArea] = useState(0);
  const {user, dispatch} = useContext(UserCtx);
  
  let navigate = useNavigate();

  const doEstimate = () => {
    return;
    if (email == ""){
      // window.location.href = "/login?redirect=civil";
      navigate("/login?redirect=civil");
    }
  }

  const saveEstimate = () => {
    let _estimate = {
      category : window.location.pathname.replace("/",""),
      service : title,
      estimates : serviceEstimate,
      plotArea: plotArea,
      builtupArea: builtupArea,
      total : total,
    };
    let _allEstimates = JSON.parse(localStorage.getItem("estimates")) || [];
    let found = false;
    for (let i = 0; i < _allEstimates.length ; i++){
      let ele = _allEstimates[i];
      if (ele.service == title ){
        ele = _estimate;
        found = true;
      }
    }
    if (!found){
      _allEstimates.push(_estimate);
    }

    localStorage.setItem("estimates",JSON.stringify(_allEstimates))
    window.NM_EVENTS.publish("SAVE_ESTIMATES");
  }

  
  
  return (
      <>
        <div className="col-sm-8 col-xs-12 col-steps" style={{ marginTop: 35 }}>
          <div className="card">
            <h5>{title} Calculator</h5>
            <hr />
            <h5>Step 1: Select Calculator</h5>
            <div className="form-group mb-25">
              <select
                className="form-control select_cal"
                onChange={(e) => setFlavor(e.target.value)}
              >
                <option value="quick">Quick Calculator</option>
                <option value="advance">Advance Calculator</option>
              </select>
            </div>
          </div>

          {flavor == "quick"? 
          <QuickCalculator
            id={id}
            title={title}
            color={color}
            serviceEstimate={serviceEstimate}
            setServiceEstimate={setServiceEstimate}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            builtupArea={builtupArea}
            setBuiltupArea={setBuiltupArea}
            total={total}
            setTotal={setTotal}
            rate = {rate}
          />
          :
          <AdvanceCalculator 
            id={id}
            title={title}
            color={color}
            serviceEstimate={serviceEstimate}
            setServiceEstimate={setServiceEstimate}
            plotArea={plotArea}
            setPlotArea={setPlotArea}
            builtupArea={builtupArea}
            setBuiltupArea={setBuiltupArea}
            total={total}
            setTotal={setTotal}
            rate = {rate}
          />
        }
        </div>
        <div className="col-sm-4 col-xs-12 calc1" style={{ marginTop: 35 }}>
          <div className="card">
            <h5>Estimated Price</h5>
            <hr />
            <p>
              Plot area:{" "}
              <span className="plot_area f-right">{plotArea} Sqft.</span>
            </p>
            <p>
              Total Buildup area:{" "}
              <span className="gf_area f-right">{builtupArea} Sqft.</span>
            </p>
            <p className="purple-color font_bold mb-20">
              {title}:
              <span className="f-right">
                <span className="Rs">₹</span>
                <span className="2d_cost">
                  {/* {email == ""? */}
                  {!user || !user.email ? 
                    <i className="fa fa-lock" aria-hidden="true">***</i>
                    :
                    serviceEstimate[title]
                  }
                </span>
              </span>
            </p>
            {/* {otherServices.filter((svc)=>(svc.service != title)&&(svc.estimate != 0)).map((svc)=>( */}
            {Object.keys(serviceEstimate)
              .filter((svc) => svc != title && serviceEstimate[svc] != 0)
              .map((svc) => (
                <p key={svc.key} className="purple-color font_bold mb-20">
                  {svc}:
                  <span className="f-right">
                    <span className="Rs">₹</span>
                    <span className="2d_cost">
                      {/* {email == ""? */}
                      {!user || !user.email ? 
                      <i className="fa fa-lock" aria-hidden="true">***</i>
                      :
                      serviceEstimate[svc]}
                    </span>
                  </span>
                </p>
              ))}
            <span className="cost-append"></span>
            <p className="purple-color font_bold mb-20">
              Total:{" "}
              <span className="f-right">
                <span className="Rs">₹</span>{" "}
                <span className="sub_cost">
                {!user || !user.email ? 
                // {email == ""?
                  <i className="fa fa-lock" aria-hidden="true">***</i>
                  :
                total} 
                </span>
              </span>
            </p>
            {!user || !user.email ? 
            // {email == ""?
            <>
            <div className="text-center">
            <button
                className="sendEstimate custom-btn purple_bg font_bold"
                style={{ background: color }}
                onClick={()=>doEstimate()}
                data-toggle="modal" data-target="#myLoginModal"
              >
                <i className="fa fa-lock-open" aria-hidden="true"></i>{" "}
                &nbsp;Estimate
              </button>
            </div>
            <hr className="border_theme" />
            </>
            :
            <>
            <div className="text-center">
            <button
                className="sendEstimate custom-btn purple_bg font_bold"
                style={{ background: color }}
                onClick={()=>saveEstimate()}
              >
                <i className="fa fa-save" aria-hidden="true"></i>{" "}
                &nbsp; Save Estimate
              </button>
              <hr className="border_theme" />
              <button
                className="custom-btn2 font_bold  mrl-10 w170 mb-10"
                style={{ color: color, border: `1px solid ${color}` }}
              >
                <i className="fa fa-shopping-cart" aria-hidden="true"></i> Add to Cart
              </button>
              <button
                className="custom-btn2 font_bold  mrl-10 w170"
                style={{ color: color, border: `1px solid ${color}` }}
              >
                <i className="fas fa-mouse-pointer"></i> Buy Now
              </button>
            </div>
            </>
            }
          </div>
        </div>
      </>
  );
};
