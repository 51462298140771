import { useEffect, useState } from "react";
import { Banners } from "../components/Banners";
import { Api } from "../libs/Api";

export const InteriorPg = ({ category, color }) => {
    const [banners, setBanners] = useState([]);

    useEffect(async () => {
        const imgResponse = await Api.get_all("images", "category=interior");
        setBanners(imgResponse.data.filter((ele) => ele.service == "banners"));
    }, []);

    return (
        <>
            <div
                id="site-page"
                className="site-page interior-page hfeed site"
                style={{ backgroundColor: "#fff", marginTop: -140 }}
            >
                <div
                    id="site-page__wrapper"
                    className="site-page__wrapper wbcontainer--fluid"
                >
                    <div id="site-main" className="site-main">
                        <main role="main" className="main-content ">
                            <div className="site-content wbcol--12">
                                <div className="site-content__inner">
                                    <article
                                        role="article"
                                        id="post-43611"
                                        className="post-43611 page type-page status-publish hentry"
                                    >
                                        <div className="entry__content">
                                            <div
                                                className="wp-block-cover alignfull has-background-dim-30 has-background-dim wp-block-cover--fullheight wp-block-cover--valignbottom"
                                                style={{
                                                    backgroundImage:
                                                        "url(/images/italian-interior-design-cover-1920x1006.jpg)",
                                                    objectFit: "cover",
                                                }}
                                            >
                                                <div className="wp-block-cover__inner-container" style={{width:'100vw',display:'flex',alignItems:'flex-end',justifyContent:'center',height:'100%'}}>
                                                    <p
                                                        className="has-text-align-center has-text-color has-large-font-size"
                                                        style={{ color: "#fff", marginTop: "550px" }}
                                                    >
                                                        Incociar Interior Design Service
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "100px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>
                                            <h2
                                                className="has-text-align-center title--centered"
                                                id="h-high-end-interior-design"
                                            >
                                                Incocicar Interior Design
                                            </h2>
                                            <div className="wbcontainer wp-block-columns espblock-2col + espblock-2col--reversed">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large">
                                                        <img
                                                            width="913"
                                                            height="1144"
                                                            src="/images/interior-design.jpg"
                                                            alt="high end interior design and moodboard composition"
                                                            className="wp-image-44045"
                                                            sizes="(max-width: 913px) 100vw, 913px"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h2
                                                        id="h-boutique-design-studio-in-milan"
                                                        style={{
                                                            textTransform: "uppercase"
                                                        }}
                                                        className={'TitlingGothicSkyline'}
                                                    >
                                                        Design Studio in Hyderabad
                                                    </h2>
                                                    <h3
                                                        style={{
                                                            fontFamily: 'Raleway, "Open Sans", sans-serif',
                                                            color: "#1b1b1b",
                                                            fontSize: "25px",
                                                            lineHeight: "1.4em",
                                                            fontWeight: "300"
                                                        }}
                                                    >
                                                        At Incociar, we strive to deliver the highest
                                                        quality of interior designs to our clients. Our
                                                        design experts come with years of profound
                                                        experience and design knowledge, coupled with a
                                                        taste for exquisite aesthetics and Vastu compliance.
                                                        <br/>
                                                        We offer avant-garde interior design compositions at
                                                        par global standards. We are Hyderabad’s most
                                                        trusted interior design company offering bespoke
                                                        plans.
                                                    </h3>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "100px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <h2
                                                className="has-text-align-center title--centered"
                                                id="h-high-end-interior-design"
                                            >
                                                High end Interior Design
                                            </h2>

                                            <div className="wbcontainer wp-block-columns espblock-2col">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large">
                                                        <img
                                                            width="1143"
                                                            height="1143"
                                                            src="/images/interior-design-fresh.jpg"
                                                            alt="interior design experience and innovative solutions"
                                                            className="wp-image-43994"
                                                            sizes="(max-width: 1143px) 100vw, 1143px"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h2
                                                        id="h-fresh-design-expertise"
                                                        style={{
                                                            textTransform: "uppercase",
                                                        }}
                                                        className={'TitlingGothicSkyline'}
                                                    >
                                                        Fresh Design Expertise
                                                    </h2>
                                                    <h3
                                                        style={{
                                                            fontFamily: 'Raleway, "Open Sans", sans-serif',
                                                            color: "#1b1b1b",
                                                            fontSize: "25px",
                                                            lineHeight: "1.4em",
                                                            fontWeight: "300"
                                                        }}
                                                    >
                                                        Our team of dexterous and creative interior
                                                        designers helps you design your home interiors in
                                                        the most unique and sustainable ways by analyzing
                                                        your requirements and aesthetic preferences. We have
                                                        experts creating world-className interiors inspired
                                                        by the design aesthetics of traditional Indian,
                                                        Italian, Moroccan, French, and Gothic masterpieces
                                                        as well as chic and contemporary design ideas like
                                                        Bohemian, geometrical, minimalistic, etc.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="wbcontainer wp-block-columns espblock-2col + espblock-2col--reversed">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large">
                                                        <img
                                                            width="1143"
                                                            height="1145"
                                                            src="/images/italian-interior-unique-turnkey-project-esperiri-milano.jpg"
                                                            alt="interior design unique services"
                                                            className="wp-image-43996"
                                                            sizes="(max-width: 1143px) 100vw, 1143px"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h2
                                                        id="h-unique-turnkey-projects"
                                                        style={{
                                                            textTransform: "uppercase"
                                                        }}
                                                        className={'TitlingGothicSkyline'}
                                                    >
                                                        Unique Turnkey Projects
                                                    </h2>
                                                    <h3
                                                        style={{
                                                            fontFamily: 'Raleway, "Open Sans", sans-serif',
                                                            color: "#1b1b1b",
                                                            fontSize: "25px",
                                                            lineHeight: "1.4em",
                                                            fontWeight: "300"
                                                        }}
                                                    >
                                                        At Incociar, we offer turnkey and eccentric interior
                                                        design services ranging from modular setup,
                                                        painting, false ceiling, lighting, marble, and
                                                        mosaic works, flooring, wallpaper, carpentry, fixed
                                                        furniture, loose furniture, furnishings, and
                                                        fittings, and more. We carefully design every
                                                        project to make it stand out in the crowd and create
                                                        a lifestyle statement resonating with the persona of
                                                        the homeowner.
                                                    </h3>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "100px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <h2
                                                className="has-text-align-center title--centered"
                                                id="h-our-project-selection"
                                            >
                                                Our Project Selection
                                            </h2>
                                            <figure
                                                id="discover_figure"
                                                style={{
                                                    margin: "0 auto",
                                                    display: "block",
                                                    width: "fit-content",
                                                }}
                                            >
                                                <img
                                                    src="/images/wallpaper.jpg"
                                                    style={{ width: "width:100%" }}
                                                />
                                                <img
                                                    id="discover_img"
                                                    src="/images/high-end-interior-design-luxury-aparment-project-esperiri-milano-1024x672.jpg"
                                                    style={{
                                                        width: "100%",
                                                        position: "absolute",
                                                        left: 0,
                                                        top: 0,
                                                        display: "none",
                                                        textAlign: "center",
                                                    }}
                                                />
                                            </figure>

                                            <div
                                                style={{ height: "30px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <div className="wbcontainer wp-block-buttons aligncenter">
                                                <div
                                                    className="wp-block-button"
                                                    style={{ margin: "50px" }}
                                                >
                                                    <a
                                                        className="wp-block-button__link"
                                                        style={{
                                                            fontFamily: '"Open Sans", sans-serif',
                                                            fontSize: "16px",
                                                        }}
                                                        href="/#work"
                                                    >
                                                        Discover Our Projects
                                                    </a>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "30px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <div
                                                style={{ background: "black", padding: "80px 0" }}
                                                className="col-sm-12"
                                            >
                                                <div className="wbcontainer">
                                                    <div className="col-sm-6">
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                minHeight: "180px",
                                                            }}
                                                        >
                                                            <img
                                                                itemprop="image"
                                                                className="qodef-dark-logo"
                                                                src="/images/logos/full_margin_transparent_white.png"
                                                                alt="dark logo"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="wp-block-column">
                                                            <h3 className="has-text-align-center">
                                                                START YOUR SHOPPING EXPERIENCE
                                                            </h3>
                                                            <p
                                                                style={{ color: "white" }}
                                                                className="has-text-align-center"
                                                            >
                                                                Let’s connect to design a home that articulates
                                                                your standard and fashion.
                                                            </p>
                                                            <br />
                                                            <div className="wp-block-buttons aligncenter">
                                                                <div className="wp-block-button wb_block_button--primary">
                                                                    <a
                                                                        className="wp-block-button__link"
                                                                        style={{
                                                                            fontFamily: '"Open Sans", sans-serif',
                                                                            fontSize: "16px",
                                                                        }}
                                                                        href="javascript:void(0);"
                                                                        title={"Coming Soon"}
                                                                    >
                                                                        Advance Interior Calculator
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "100px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <h2
                                                className="has-text-align-center title--centered"
                                                id="h-our-italian-interior-design-process"
                                            >
                                                Our Design Process
                                            </h2>
                                            <div
                                                style={{ height: "40px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <div className="wbcontainer wp-block-columns espblock-2col">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large">
                                                        <img
                                                            width="1375"
                                                            height="917"
                                                            src="/images/italian-interior-design-process-01-about-you-analysis-esperiri-milano.jpg"
                                                            alt="interior design first project phase"
                                                            className="wp-image-44057"
                                                            sizes="(max-width: 1375px) 100vw, 1375px"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h2
                                                        id="h-about-you"
                                                        style={{
                                                            textTransform: "uppercase"
                                                        }}
                                                        className={'TitlingGothicSkyline'}
                                                    >
                                                        Knowing the customer
                                                    </h2>
                                                    <h3
                                                        style={{
                                                            fontFamily: 'Raleway, "Open Sans", sans-serif',
                                                            color: "#1b1b1b",
                                                            fontSize: "25px",
                                                            lineHeight: "1.4em",
                                                            fontWeight: "300"
                                                        }}
                                                    >
                                                        Every home is special and unique, be it a
                                                        traditional and old villa or a new and contemporary
                                                        apartment. It speaks volumes of the stories and
                                                        milestones created and also those yet to be etched.
                                                        Hence, our designers strive to know each customer
                                                        better to cater to the interior design needs at a
                                                        deeper and more nuclear level.
                                                    </h3>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "30px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <div className="wbcontainer wp-block-columns espblock-2col + espblock-2col--reversed">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large">
                                                        <img
                                                            width="1146"
                                                            height="764"
                                                            src="/images/italian-interior-design-process-02-planning-esperiri-milano.jpg"
                                                            alt="high end interior designers inspirations and materials solutions"
                                                            className="wp-image-44049"
                                                            sizes="(max-width: 1146px) 100vw, 1146px"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h2
                                                        id="h-planning"
                                                        style={{
                                                            textTransform: "uppercase"
                                                        }}
                                                        className={'TitlingGothicSkyline'}
                                                    >
                                                        Planning
                                                    </h2>
                                                    <h3
                                                        style={{
                                                            fontFamily: 'Raleway, "Open Sans", sans-serif',
                                                            color: "#1b1b1b",
                                                            fontSize: "25px",
                                                            lineHeight: "1.4em",
                                                            fontWeight: "300"
                                                        }}
                                                    >
                                                        Our design maestros create the most befitting and
                                                        bewitching interior design plans based on the
                                                        customers’ requirements, trending fashion and styles
                                                        in both Indian and international markets, and Vastu
                                                        standards. The designs are finalized in consultation
                                                        with the clients before we leap into the next stage.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div
                                                style={{ height: "30px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>
                                            <div className="wbcontainer wp-block-columns espblock-2col">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large">
                                                        <img
                                                            width="1719"
                                                            height="1146"
                                                            src="/images/italian-interior-design-process-03-ste-management-esperiri-milano.jpg"
                                                            alt="italian interior design project and site management"
                                                            className="wp-image-44051"
                                                            sizes="(max-width: 1719px) 100vw, 1719px"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h2
                                                        id="h-site-management"
                                                        style={{
                                                            textTransform: "uppercase"
                                                        }}
                                                        className={'TitlingGothicSkyline'}
                                                    >
                                                        Site Management
                                                    </h2>
                                                    <h3
                                                        style={{
                                                            fontFamily: 'Raleway, "Open Sans", sans-serif',
                                                            color: "#1b1b1b",
                                                            fontSize: "25px",
                                                            lineHeight: "1.4em",
                                                            fontWeight: "300"
                                                        }}
                                                    >
                                                        Our squad of expert interior designers strives to
                                                        deliver the best support to the clients at every
                                                        phase of the design and execution, including
                                                        site-level project execution. Our experts will be
                                                        available for site visits and supervision throughout
                                                        the project phase (design actualization to
                                                        installation) to ensure 100% accuracy, transparency,
                                                        efficiency, and quality.
                                                    </h3>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "30px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <div
                                                style={{ height: "30px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <div className="wbcontainer wp-block-columns espblock-2col">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large">
                                                        <img
                                                            width="1375"
                                                            height="917"
                                                            src="/images/italian-interior-design-process-05-procurement-installation-esperiri-milano.jpg"
                                                            alt="high end interior design furniture procurement and installation by Italian craftsmen"
                                                            className="wp-image-44055"
                                                            sizes="(max-width: 1375px) 100vw, 1375px"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h2
                                                        id="h-procurement-installation"
                                                        style={{
                                                            textTransform: "uppercase"
                                                        }}
                                                        className={'TitlingGothicSkyline'}
                                                    >
                                                        Procurement &amp; Installation
                                                    </h2>
                                                    <h3
                                                        style={{
                                                            fontFamily: 'Raleway, "Open Sans", sans-serif',
                                                            color: "#1b1b1b",
                                                            fontSize: "25px",
                                                            lineHeight: "1.4em",
                                                            fontWeight: "300"
                                                        }}
                                                    >
                                                        Upon finalizing the design, the dedicated Incocair
                                                        team will immediately commence the project
                                                        realization phase. We will start the procurement of
                                                        the items in the itinerary and make sure that the
                                                        quality is maintained throughout the process. Once
                                                        the products are fetched, they are assembled and
                                                        installed at the customer site with utmost
                                                        precision.
                                                    </h3>

                                                    <div
                                                        style={{ height: "20px" }}
                                                        aria-hidden="true"
                                                        className="wp-block-spacer"
                                                    ></div>

                                                    <div className="wp-block-buttons">
                                                        <div className="wp-block-button">
                                                            <a
                                                                className="wp-block-button__link"
                                                                href="#"
                                                                target="_blank"
                                                                rel="noreferrer noopener"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "16px",
                                                                }}
                                                            >
                                                                Discover More
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "100px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <div
                                                style={{ height: "100px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <h2
                                                className="has-text-align-center title--centered"
                                                id="h-our-network-our-value"
                                            >
                                                Our Network, Our Value
                                            </h2>

                                            <div className="wbcontainer wp-block-columns espblock-bannerbg">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-gallery columns-2 is-cropped">
                                                        <ul className="blocks-gallery-grid">
                                                            <li className="blocks-gallery-item">
                                                                <figure>
                                                                    <img
                                                                        width="1146"
                                                                        height="764"
                                                                        src="/images/italian-interior-design-network-trustworthy-suppliers-esperiri-milano.jpg"
                                                                        alt=""
                                                                        data-id="44025"
                                                                        data-link="#"
                                                                        className="wp-image-44025"
                                                                        sizes="(max-width: 1146px) 100vw, 1146px"
                                                                    />
                                                                </figure>
                                                            </li>
                                                            <li className="blocks-gallery-item">
                                                                <figure>
                                                                    <img
                                                                        width="1146"
                                                                        height="764"
                                                                        src="/images/italian-interior-design-network-professionals-esperiri-milano-1.jpg"
                                                                        alt=""
                                                                        data-id="44028"
                                                                        data-link="#"
                                                                        className="wp-image-44028"
                                                                        sizes="(max-width: 1146px) 100vw, 1146px"
                                                                    />
                                                                </figure>
                                                            </li>
                                                        </ul>
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h2
                                                        id="h-trustworthy-suppliers"
                                                        style={{
                                                            textTransform: "uppercase"
                                                        }}
                                                        className={'TitlingGothicSkyline'}
                                                    >
                                                        Trustworthy Suppliers
                                                    </h2>
                                                    <h3
                                                        style={{
                                                            fontFamily: 'Raleway, "Open Sans", sans-serif',
                                                            color: "#1b1b1b",
                                                            fontSize: "25px",
                                                            lineHeight: "1.4em",
                                                            fontWeight: "300"
                                                        }}
                                                    >
                                                        Incocair has the largest network of the country’s
                                                        best manufacturers and suppliers of interior design
                                                        supplies, as well as the best contractors, laborers,
                                                        engineers, electricians, plumbers, etc. This makes
                                                        us one of the most trusted interior design players
                                                        in the market. We boast 100% client satisfaction
                                                        through impeccable services.
                                                    </h3>
                                                    <div
                                                        style={{ height: "20px" }}
                                                        aria-hidden="true"
                                                        className="wp-block-spacer"
                                                    ></div>
                                                    <div className="wp-block-buttons aligncenter">
                                                        <div className="wp-block-button">
                                                            <a
                                                                className="wp-block-button__link"
                                                                href="#"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "16px",
                                                                }}
                                                            >
                                                                Discover More
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "50px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <div
                                                style={{ height: "50px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <div className="wbcontainer wp-block-columns espblock-bannerbg">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-gallery columns-2 is-cropped">
                                                        <ul className="blocks-gallery-grid">
                                                            <li className="blocks-gallery-item">
                                                                <figure>
                                                                    <img
                                                                        width="1489"
                                                                        height="993"
                                                                        src="/images/italian-interior-design-craftmanship-esperiri-milano.jpg"
                                                                        alt=""
                                                                        data-id="44034"
                                                                        className="wp-image-44034"
                                                                        sizes="(max-width: 1489px) 100vw, 1489px"
                                                                    />
                                                                </figure>
                                                            </li>
                                                            <li className="blocks-gallery-item">
                                                                <figure>
                                                                    <img
                                                                        width="1489"
                                                                        height="993"
                                                                        src="/images/italian-interior-design-bespoke-furniture-esperiri-milano.jpg"
                                                                        alt=""
                                                                        data-id="44035"
                                                                        data-link="#"
                                                                        className="wp-image-44035"
                                                                        sizes="(max-width: 1489px) 100vw, 1489px"
                                                                    />
                                                                </figure>
                                                            </li>
                                                        </ul>
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h2
                                                        id="h-craftsmanship-italian-bespoke-furniture"
                                                        style={{
                                                            textTransform: "uppercase"
                                                        }}
                                                        className={'TitlingGothicSkyline'}
                                                    >
                                                        Craftsmanship &amp; Bespoke Furniture
                                                    </h2>
                                                    <h3
                                                        style={{
                                                            fontFamily: 'Raleway, "Open Sans", sans-serif',
                                                            color: "#1b1b1b",
                                                            fontSize: "25px",
                                                            lineHeight: "1.4em",
                                                            fontWeight: "300"
                                                        }}
                                                    >
                                                        Our team of excellent interior designers and a
                                                        network of the finest craftsmen work hand in hand to
                                                        create the best designs and products that are both
                                                        unique and of premium quality. We offer bespoke
                                                        designs to our clients to help them create a
                                                        trendsetting style statement for their
                                                        establishment, be it their home or a commercial
                                                        space.
                                                    </h3>
                                                    <div className="wp-block-buttons aligncenter">
                                                        <div className="wp-block-button">
                                                            <a
                                                                className="wp-block-button__link"
                                                                href="#"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "16px",
                                                                }}
                                                            >
                                                                Discover More
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                style={{ height: "50px" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>
                                            <div
                                                style={{ height: "80px", backgroundColor: "#000" }}
                                                aria-hidden="true"
                                                className="wp-block-spacer"
                                            ></div>

                                            <h2
                                                className="has-text-align-center title--centered"
                                                id="h-our-italian-interior-design-process"
                                                style={{
                                                    marginBottom: '0px',
                                                    paddingBottom: '40px',
                                                    backgroundColor: "#000",
                                                }}
                                            >
                                                The Incociar DIfference
                                            </h2>

                                            <div
                                                className="col-md-12 col-sm-12 ourvalues"
                                                style={{
                                                    backgroundColor: "#000",
                                                    color: "#fff",
                                                    textAlign: "center",
                                                    paddingBottom: 60
                                                }}
                                            >
                                                <div className="wbcontainer row g-4">
                                                    <div className="galmargin col-md-4 col-sm-6">
                                                        <div className="sol-card">
                                                            <div className="imgbox">
                                                                <img
                                                                    style={{ width: "50px" }}
                                                                    src="http://juztag.com/showcase/cal-on/images/certifications.png"
                                                                />
                                                            </div>
                                                            <h4
                                                                className="gal-para"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "25px",
                                                                    textTransform: "uppercase",
                                                                    color: '#fff'
                                                                }}
                                                            >
                                                                Premium Quality Assurance
                                                            </h4>
                                                            <p
                                                                className="gal-des"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "20px",
                                                                }}
                                                            >
                                                                We guarantee the best quality for all our
                                                                products, materials, and services
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="galmargin col-md-4 col-sm-6">
                                                        <div className="sol-card">
                                                            <div className="imgbox">
                                                                <img
                                                                    style={{ width: "50px" }}
                                                                    src="http://juztag.com/showcase/cal-on/images/curious.png"
                                                                />
                                                            </div>
                                                            <h4
                                                                className="gal-para"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "25px",
                                                                    textTransform: "uppercase",
                                                                    color: '#fff'
                                                                }}
                                                            >
                                                                Bespoke Designs
                                                            </h4>
                                                            <p
                                                                className="gal-des"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "20px",
                                                                }}
                                                            >
                                                                We create unique and customized designs for each
                                                                project based on customer needs & aspirations
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="galmargin col-md-4 col-sm-6">
                                                        <div className="sol-card">
                                                            <div className="imgbox">
                                                                <img
                                                                    style={{ width: "50px" }}
                                                                    src="http://juztag.com/showcase/cal-on/images/sales.png"
                                                                />
                                                            </div>
                                                            <h4
                                                                className="gal-para"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "25px",
                                                                    textTransform: "uppercase",
                                                                    color: '#fff'
                                                                }}
                                                            >
                                                                Complete Transparency
                                                            </h4>
                                                            <p
                                                                className="gal-des"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "20px",
                                                                }}
                                                            >
                                                                We provide detailed cost breakups for every item
                                                                and service on the itenary with no hidden costs
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div id="centregals">
                                                        <div className="galmargin col-md-4 col-sm-6">
                                                            <div className="sol-card">
                                                                <div className="imgbox">
                                                                    <img
                                                                        style={{ width: "50px" }}
                                                                        src="http://juztag.com/showcase/cal-on/images/space.png"
                                                                    />
                                                                </div>
                                                                <h4
                                                                    className="gal-para"
                                                                    style={{
                                                                        fontFamily: '"Open Sans", sans-serif',
                                                                        fontSize: "25px",
                                                                        textTransform: "uppercase",
                                                                        color: '#fff'
                                                                    }}
                                                                >
                                                                    Seamless delivery & installation
                                                                </h4>
                                                                <p
                                                                    className="gal-des"
                                                                    style={{
                                                                        fontFamily: '"Open Sans", sans-serif',
                                                                        fontSize: "20px",
                                                                    }}
                                                                >
                                                                    We guarantee on-time and seamless delivery and
                                                                    meticulous installation process.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="galmargin col-md-4 col-sm-6">
                                                            <div className="sol-card">
                                                                <div className="imgbox">
                                                                    <img
                                                                        style={{ width: "50px" }}
                                                                        src="http://juztag.com/showcase/cal-on/images/trust.png"
                                                                    />
                                                                </div>
                                                                <h4
                                                                    className="gal-para"
                                                                    style={{
                                                                        fontFamily: '"Open Sans", sans-serif',
                                                                        fontSize: "25px",
                                                                        textTransform: "uppercase",
                                                                        color: '#fff'
                                                                    }}
                                                                >
                                                                    After sales support & product warranty
                                                                </h4>
                                                                <p
                                                                    className="gal-des"
                                                                    style={{
                                                                        fontFamily: '"Open Sans", sans-serif',
                                                                        fontSize: "20px",
                                                                    }}
                                                                >
                                                                    Our journey with our customers is for a
                                                                    lifetime with impeccable after sales service
                                                                    and product guarantee
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="wbcontainer wp-block-group alignfull has-background" style={{ backgroundColor: '#fff' }}>
                                                <div className="wp-block-group__inner-container">
                                                    <div className="wp-block-columns are-vertically-aligned-center">
                                                        <div className="wp-block-column is-vertically-aligned-center">
                                                            <figure className="wp-block-image size-large">
                                                                <img
                                                                    width="1489"
                                                                    height="993"
                                                                    src="/images/italian-interior-design-bespoke-furniture-esperiri-milano.jpg"
                                                                    alt="" data-id="44035"
                                                                    data-link="#"
                                                                    className="wp-image-44035"
                                                                    sizes="(max-width: 1489px) 100vw, 1489px"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div
                                                            className="wp-block-column is-vertically-aligned-center">
                                                            <h2 className="has-text-align-center title--centered"
                                                                id="h-your-interior-design-project-with-us"
                                                                style={{
                                                                    fontFamily: '"Open Sans", sans-serif',
                                                                    fontSize: "32px",
                                                                }}
                                                            >
                                                                Your Interior Design Project
                                                                with Us</h2>
                                                            <p className="has-text-align-center" style={{
                                                                fontFamily: '"Open Sans", sans-serif',
                                                                fontSize: "20px",
                                                            }}>
                                                                Let’s connect to create a
                                                                beautiful abode that adds to
                                                                your style statement and making
                                                                your home stand out like a show
                                                                stopper. </p>
                                                            <div style={{ height: '20px' }}
                                                                aria-hidden="true"
                                                                className="wp-block-spacer"></div>
                                                            <div
                                                                className="wp-block-buttons aligncenter">
                                                                <div className="wp-block-button"><a
                                                                    className="wp-block-button__link"
                                                                    href="#" style={{
                                                                        fontFamily: '"Open Sans", sans-serif',
                                                                        fontSize: "16px",
                                                                    }}>Contact Us</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </article>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    );
};
