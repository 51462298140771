import React from "react";

export const PaintingEstimationCard = ({ roomsDetails, total, area }) => {
  return (
    <div className="card">
      <h5>Estimated Price</h5>
      <hr />
      {roomsDetails.map((room, idx) => (
      <>  
        <p style={{display:"none"}}>
          {room.name}: <br />
          Area:({room.wallArea}|{room.noWallArea}|{room.paintArea})
          {["A", "B", "C", "D"].map((wName) => (
            <div>
              Wall {wName} | {room[`wall${wName}`].width} x{" "}
              {room[`wall${wName}`].height} = {room[`wall${wName}`].wallArea} |
              No Wall - {room[`wall${wName}`].noWallArea} | ={" "}
              {room[`wall${wName}`].paintArea} * {room[`wall${wName}`].rate} ={" "}
              {room[`wall${wName}`].price}
            </div>
          ))}
          {room.includeCeiling ? (
            <div>
              {" "}
              Ceiling {room.len}x{room.width} = {room.len * room.width}
            </div>
          ) : (
            ""
          )}
        </p>
        
      <p className="purple-color font_bold mb-20">
                {room.name} Paint Price:({room.paintArea && (room.paintArea).toFixed(1)})sq ft.
                <span className="f-right">
                  <span className="Rs">&#8377;</span>{" "}
                  <span className="room_cost">{room.totalPrice && room.totalPrice.toFixed(1)}</span>
                </span>
              </p>{" "}
      <span className="cost-append"></span>
      </>
      ))}
      <p className="purple-color font_bold mb-20">
        {" "}
        Total Price: (
        {area && area.toFixed(1)}{" "}
        sq.ft);
        <span className="f-right">
          <span className="Rs">&#8377;</span>{" "}
          <span className="sub_cost">{total && total.toFixed(1)}</span>
        </span>
      </p>
      <div className="text-center">
        <button
          className="sendEstimate custom-btn purple_bg font_bold"
          style={{ background: "#4296c7" }}
        >
          <i className="fa fa-paper-plane" aria-hidden="true"></i> &nbsp;Send
          Estimation
        </button>
      </div>
      <hr className="border_theme" />
      <div className="text-center">
        <button
          className="custom-btn2 font_bold  mrl-10 w170 mb-10"
          style={{ color: "#4296c7", borderColor: "#4296c7" }}
        >
          <i className="fa fa-shopping-cart" aria-hidden="true"></i> Add to Cart
        </button>
        <button
          className="custom-btn2 font_bold  mrl-10 w170"
          style={{ color: "#4296c7", borderColor: "#4296c7" }}
        >
          <i className="fas fa-mouse-pointer"></i> Buy Now
        </button>
      </div>
    </div>
  );
};
