import axios from "axios";
import {Config} from "../Config"

let endpoint = {
  // base: "http://localhost",
  // port: "3001",
  db : 'db',
};

// endpoint.db_uri = `${endpoint.base}:${endpoint.port}/${endpoint.db}`;
// endpoint.upload_images_uri = `${endpoint.base}:${endpoint.port}${endpoint.upload_images}`;

endpoint.db_uri = `${Config.API_BASE}/${endpoint.db}`;
endpoint.upload_images_uri = `${Config.API_BASE}${endpoint.upload_images}`;



export const Api = {
  endpoint : endpoint,
  get_all : function(collection,filter){
    const query = filter?`?${filter}`:"";
    return (
      axios.get(`${endpoint.db_uri}/${collection}/all${query}`)
    )
  },
  get_one : function(collection,_id){
    return (
      axios.get(`${endpoint.db_uri}/${collection}/one?id=${_id}`)
    )
  },
  update : function(collection,params){
    return (
      axios.put(`${endpoint.db_uri}/${collection}/one`, params)
    )
  },
  save : function(collection,params){
    return (
      axios.post(`${endpoint.db_uri}/${collection}/one`, params)
    )
  },
  delete : function(collection,_id, image){
    return (
      axios.delete(`${endpoint.db_uri}/${collection}/one?id=${_id}&image=${image}`)
    )
  },
  get_count : function(collection){
    return (
      axios.get(`${endpoint.db_uri}/${collection}/count`)
    )
  },
  createAccount : function(params){
    return (
      axios.post(`${Config.API_BASE}/auth/account`,params)
    )
  },
  authenticate: function (params){
    return (
      axios.post(`${Config.API_BASE}/login/users`, params)
    )
  },
  email: function (params){
    return (
      axios.post(`${Config.API_BASE}/sg-mail/text`, params)
    )
  },
};
