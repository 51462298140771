import React from 'react'

export const ConfirmDeleteDlg = ({color}) => {
  return (
    <div id="myModal" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
              <h4 className="modal-title">
                <b>Delete Floor</b>
              </h4>{" "}
            </div>
            <div className="modal-body">
              <p className="del-popuptext">
                Do you wish to remove Floor 2 details?
              </p>
              <p>
                <b>Please Note:</b>
              </p>
              <p>
                Deleting the details of this floor will result in change from
                the total estimated budget
              </p>
            </div>
            <div className="modal-footer" style={{ textAlign: "center" }}>
              <button
                style={{ backgroundColor:color }}
                type="button"
                id="delete-floor-btn"
                className="custom-btn3 font_bold"
                data-dismiss="modal"
                floorid="1dfloor2"
                onClick={()=>{window.NM_CALLBACKS.confirmDelete()}}
              >
                Confirm
              </button>
              <br />
              <button
                type="button"
                className="custom-btn4"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}
