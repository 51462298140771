import React from "react";

export const FileUpload = ({title}) => {
  return (
    <div className="a-cal card">
      <h5>{title}</h5>
      <hr />
      <div className="row">
        <div className="col-sm-4 w178">
          <p className="font_bold">Upload File</p>
        </div>
        <div className="col-sm-8 w185 fileUploadInput">
          <label>
            {" "}
            <span className="form-control text-center w195 cursor">
              <i className="fas fa-cloud-upload-alt" aria-hidden="true"></i>
            </span>
            <input type="file" multiple />{" "}
          </label>
        </div>
      </div>
    </div>
  );
};
