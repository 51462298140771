import React, { useState,useEffect } from "react";
import { ConfirmDeleteDlg } from "../components/ConfirmDeleteDlg";
import { LoginModal } from "../components/LoginModal";
import "../libs/Nm_Events";
import {Api} from "../libs/Api";
import {Pg} from "../Pg"
import { PaintingIntro } from "../components/PaintingIntro";
import { PaintingServiceCards } from "../components/PaintingServiceCards";
import { PaintingSections } from "./PaintingSections";
import { Swiper } from "../components/Swiper";


export const PaintingPg = ({ category, color }) => {
  const _servicesZero = Pg[category].calculators.reduce((ac,a)=>({...ac,[a]:0}),{});
  // _servicesZero = {
  //   "Structural Drawing": 0,
  //   "Working Drawing": 0,
  //   "Electrical Drawing": 0,
  //   "Plumbing Drawing": 0,
  // }
  const [serviceEstimate, setServiceEstimate] = useState(_servicesZero);
  const [rate, setRate] = useState(_servicesZero);
  const [total, setTotal] = useState(0);
  const [email,setEmail] = useState(localStorage.getItem("email") || "") ;

  const [banners, setBanners] = useState([{image:""}]);
  const [images, setImages] = useState([{image:""}]);
  const [paintingServiceImgs, setPaintingServiceImgs] = useState([{image:"",category:""},{image:"",category:""},{image:"",category:""},{image:"",category:""}]);
  const [interiorImgs, setInteriorImgs] = useState([{image:"",category:""}]);
  const [exteriorImgs, setExteriorImgs] = useState([{image:"",category:""}]);
  const [commericialImgs, setCommercialImgs] = useState([{image:"",category:""}]);
  const [bookASiteImgs, setBookASiteImgs] = useState([{image:"",category:""}]);
  const [measurementImgs, setMeasurementImgs] = useState([{image:"",category:""}]);
  const [letsDesignImgs, setLetsDesignImgs] = useState([{image:"",category:""}]);
  const [projectCompletionImgs, setProjectCompletionImgs] = useState([{image:"",category:""}]);
  const [freeVisitImgs, setFreeVisitImgs] = useState([{image:"",category:""},{image:"",category:""},{image:"",category:""},{image:"",category:""}]);
  const [giveBuzzImgs, setGiveBuzzImgs] = useState([{image:"",category:""}]);

  const [swiper, setSwiper] = useState([{image:""}]);


  useEffect(async()=>{
    const imgResponse = await Api.get_all("images","category=painting");
    console.log("response painting images",imgResponse);

    setImages(imgResponse.data.filter((ele)=>(ele.service != "banners" && ele.service != "Swiper" && ele.service != "Incociar Painting Services")));
    setBanners(imgResponse.data.filter((ele)=>ele.service == "banners"));
    setPaintingServiceImgs(imgResponse.data.filter((ele)=>ele.service == "Incociar Painting Services"));
    setInteriorImgs(imgResponse.data.filter((ele)=>ele.service == "Interior Painting"));
    setExteriorImgs(imgResponse.data.filter((ele)=>ele.service == "Exterior Painting"));
    setCommercialImgs(imgResponse.data.filter((ele)=>ele.service == "Commercial Painting"));
    setBookASiteImgs(imgResponse.data.filter((ele)=>ele.service == "Book a Site Visit"));
    setMeasurementImgs(imgResponse.data.filter((ele)=>ele.service == "Measurements and Estimation"));
    setLetsDesignImgs(imgResponse.data.filter((ele)=>ele.service == "Lets Design Vibrant Spaces Together"));
    setProjectCompletionImgs(imgResponse.data.filter((ele)=>ele.service == "Project completion and Quality Assurance"));
    setFreeVisitImgs(imgResponse.data.filter((ele)=>ele.service == "Book A Free Site Visit"));
    setGiveBuzzImgs(imgResponse.data.filter((ele)=>ele.service == "Give us a Buzz"));
    
    setSwiper(imgResponse.data.filter((ele)=>ele.service == "Swiper"));



    window.NM_EVENTS.subscribe("USER_LOGGED_IN", ()=>{
      setEmail(localStorage.getItem("email") || "");
    });

    const response = await Api.get_all("service_rates",`category=${category}`);
    console.log('rate:resp',response);
    
    let _rate = {};
    response.data.forEach((ele) => {
      _rate[ele.service] = ele.rate;
    });
    console.log("useEffect Civil, rate set to",_rate);
    setRate(_rate);

  },[])

  return (
    <>
      <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.min.css"    />
      <link href="css/painting.css" rel="stylesheet" type="text/css" />

      <div className="content-body">

      <PaintingIntro banner={banners[0]}  paintingServiceImgs={paintingServiceImgs}/>
      <PaintingServiceCards interiorImg={interiorImgs[0]} exteriorImg={exteriorImgs[0]} commericialImg={commericialImgs[0]}/>
      <PaintingSections bookASiteImg={bookASiteImgs[0]} measurementImg={measurementImgs[0]} letsDesignImg={letsDesignImgs[0]} projectCompletionImg={projectCompletionImgs[0]} giveBuzzImg={giveBuzzImgs[0]} freeVisitImgs={freeVisitImgs}/>
      <Swiper images={swiper}/>
      
      <ConfirmDeleteDlg color={color} />

      <div id="myLoginModal" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content-->  */}
          <div className="modal-content" >
            <div className="modal-body" style={{ padding: "15px 25px" }}>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>

              {/* <div> 
                <p className="login_title"><b>Already have an account?<br/>
                Please login here</b></p> <br/>
                <form action="" method="post">
                    <label>E-mail</label>
                    <input type="text" name="user_login"/> 
                    <label>Password:</label>
                    <input type="password" name="user_pass"/> 
                <p><small>Forgot your password? Click <a style={{color: "#4296c7"}} href="#">here</a></small></p> 
                <button className="btn btn-primary" type="submit">Log in</button>
                </form>
                </div> */}

              <LoginModal />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
