import React, { useState, useEffect } from "react";
import { FileUpload } from "./FileUpload";

export const AdvanceCalculator = ({
  id,
  title,
  color,
  serviceEstimate,
  setServiceEstimate,
  plotArea,
  setPlotArea,
  builtupArea,
  setBuiltupArea,
  total,
  setTotal,
  rate,
}) => {
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  // const [nFloors, setNFloors] = useState(1);
  const [gfBuiltUpArea, setGfBuiltUpArea] = useState(0);
  const [floors, setFloors] = useState([{ name: "Floor 1", area: 0 }]);


  function calculateAreas(width, length , inFloors){
    setPlotArea(width * length);
    let _gfBuiltUpArea = 9.84 * width + 6.56 * length - width*length;
    setGfBuiltUpArea ((_gfBuiltUpArea).toFixed(1));
    let _builtUpArea = inFloors.reduce((sum, ele)=> sum + Number(ele.area) ,0);
    _builtUpArea = Number(_builtUpArea).toFixed(1) - (-Number(_gfBuiltUpArea).toFixed(1));  //+ is not working for some reason
    console.log('total build up area',_builtUpArea);
    setBuiltupArea(_builtUpArea);
    return _builtUpArea;
  }

  function changeGfBuiltUpArea  (width, length){
    setGfBuiltUpArea ((9.84 * width + 6.56 * length - width*length).toFixed(1));
    // setGfBuiltUpArea (width*length);
  }

  function totalBuiltUpArea (inFloors){
    let totalArea = inFloors.reduce((sum, ele)=> Number(sum) + Number(ele.area) ,0);
    totalArea += Number(gfBuiltUpArea);
    console.log('total build up area',totalArea);
    setBuiltupArea(totalArea);
    return totalArea;
  }

  const setMyServiceEstimate = (value) => {
    let _serviceEstimate = JSON.parse(JSON.stringify(serviceEstimate));
    _serviceEstimate[title] = value;
    setServiceEstimate(_serviceEstimate);
    let _total = Object.keys(_serviceEstimate).reduce((sum, key)=>sum+_serviceEstimate[key], 0);
    setTotal(_total);
  };

  const changeLength = (value) => {
    setLength(value);
    // setPlotArea(width * value);
    // let _gfBuiltUpArea = changeGfBuiltUpArea (width, value);
    // let _builtUpArea = totalBuiltUpArea(floors, )
    // setBuiltupArea(_builtUpArea);

    let _builtUpArea = calculateAreas(width, value , floors)
    setMyServiceEstimate(rate[title] * _builtUpArea);
  };
  const changeWidth = (value) => {
    setWidth(value);
    // setPlotArea(value * length);
    // let _gfBuiltUpArea = changeGfBuiltUpArea (value, length)
    // totalBuiltUpArea(floors);

    let _builtUpArea = calculateAreas(value, length , floors)
    setMyServiceEstimate(rate[title] * _builtUpArea);
  };
  // const changeNFloors = (value) => {
  //   setNFloors(value);
  //   totalBuiltUpArea(floors)
  //   setMyServiceEstimate(rate[title] * plotArea * value);
  // };

  const addFloor = () => {
    let _floors = JSON.parse(JSON.stringify(floors));
    _floors.push({ name: `Floor ${_floors.length + 1}`, area: 0 });
    setFloors(_floors);
  };

  const editFloor = (idx) => {
    let _floors = JSON.parse(JSON.stringify(floors));
    _floors[idx].isEditing = true;
    setFloors(_floors);
  };
  const saveFloor = async(idx, params) => {
    let _floors = JSON.parse(JSON.stringify(floors));
    for (let key in params){
      _floors[idx][key] = params[key];  
    }
    // _floors[idx].name = document.getElementById(`floor${idx}Id`).innerText;;
    _floors[idx].isEditing = false;
    setFloors(_floors);
    // totalBuiltUpArea(_floors)
    let _builtUpArea = calculateAreas(width, length , _floors)
    setMyServiceEstimate(rate[title] * _builtUpArea);
  };

  const deleteFloor = (idx) => {
    floorToDelete = idx;
    
  };

  //using API for id get the rate[title]

  let floorToDelete = 1;
  window.NM_CALLBACKS.confirmDelete = () =>{
    console.log ("window Confirm callback");
    let _floors = JSON.parse(JSON.stringify(floors));
    _floors.splice(floorToDelete,1);
    setFloors(_floors);
  }
  useEffect(async () => {
    console.log("useEffect Advanced Calc");
    // effect
    let _total = Object.keys(serviceEstimate).reduce((sum, key)=>sum + serviceEstimate[key], 0);
    setTotal(_total);
    

    return () => {
      // cleanup
    };
  }, []);


  return (
    <div>
      <div className="a-cal card">
        <h5>Step 2: Area</h5>
        <hr />
        <div className="row">
          <div className="col-xs-6 col-sm-6">
            <label className="radio-inline">
              <label htmlFor="usr">Width</label>
              <input
                type="number"
                name="width"
                value={length}
                onChange={(e) => changeLength(e.target.value)}
              />
              &nbsp; <span className="ipunits">Ft.</span>{" "}
            </label>
          </div>
          <div className="col-xs-6 col-sm-6">
            <label className="radio-inline">
              <label htmlFor="usr">Length</label>
              <input
                type="number"
                name="length"
                value={width}
                onChange={(e) => changeWidth(e.target.value)}
              />
              &nbsp; <span className="ipunits">Ft.</span>{" "}
            </label>
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="radio-inline">
              <label>GF Buildup Area</label>
              <input
                type="number"
                name="gfbuilduparea"
                readOnly={true}
                value={gfBuiltUpArea}
              />
              &nbsp; <span className="ipunits">Sqft.</span>{" "}
            </label>
          </div>

          {floors.map((floor, idx) => (
            <div className="div-floor" id="2dfloors" key={idx}>
              <div className="col-xs-12 col-sm-12 col-floor" id="2dfloor1">
                <label className="radio-inline">
                  <label>
                    <span id = {`floor${idx}Id`} className="flrname" contentEditable={floor.isEditing }>{floor.name}</span> &nbsp;{" "}
                    <i
                      className={`fas ${floor.isEditing?"fa-check":"fa-pen"}`}
                      onClick={() => {
                        if (floor.isEditing)
                          saveFloor(idx, {name : document.getElementById(`floor${idx}Id`).innerText});
                        else 
                          editFloor(idx);
                      }}
                    ></i>
                    <span className="dashline"> | </span>{" "}
                    <i
                      data-toggle="modal"
                      data-target="#myModal"
                      className="del_floor far fa-times-circle"
                      onClick={() => deleteFloor(idx)}
                    ></i>
                  </label>
                  <input type="number" name="floor" 
                  value={floor.area}
                  onChange={(e) => saveFloor(idx,{area: (e.target.value)})}
                  />
                  &nbsp; <span className="ipunits">Sqft.</span>{" "}
                </label>
              </div>
            </div>
          ))}

          <div className="col-xs-12 ptb-10" onClick={() => addFloor()}>
            <div className="add_floor radio-inline">
              {" "}
              <i className="fas fa-plus-circle"></i>{" "}
              <span className="font_bold">Add Floor</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <FileUpload title = "Step 3: Upload"/>
      
    </div>
  );
};
