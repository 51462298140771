import React from "react";

export const SideMenu = () => {
  return (
    <>
      <section className="qodef-side-menu ps ps--theme_default">
        <div className="menu">
          {" "}
          <i className="menu__close">
            <a
              className="qodef-close-side-menu qodef-close-side-menu-predefined"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#fff"
                  d="M24 22.568l-1.433 1.432-10.567-10.568-10.568 10.568-1.432-1.432 10.568-10.568-10.568-10.568 1.432-1.432 10.568 10.567 10.567-10.567 1.433 1.432-10.568 10.568 10.568 10.568z"
                ></path>
              </svg>
            </a>
          </i>
          <menu className="menu__content">
            {" "}
            <a className="menu__item menu__item_work" href="/#work">
              Projects
            </a>{" "}
            <a className="menu__item menu__item_clients" href="/interior">
              Interiors
            </a>{" "}
            <a className="menu__item menu__item_about" title="Coming Soon" href="javascript:void(0);">
              Construction
            </a>
            {
              /** <li className="dropdown menu__item">
              <a
                className="menu__item  dropdown-toggle"
                data-toggle="dropdown"
                href=""
                aria-expanded="false"
              >
                {" "}
                <span className="item_outer">
                  <span className="item_text">
                    Civil<span className="caret"></span>
                  </span>
                </span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="#">Structural Drawings</a>
                </li>
                <li>
                  <a href="#">Working Drawings</a>
                </li>
                <li>
                  <a href="#">Electrical</a>
                </li>
                <li>
                  <a href="#">Plumbing Drawings</a>
                </li>
              </ul>
            </li>
            <li className="dropdown menu__item">
              <a
                className="menu__item  dropdown-toggle"
                data-toggle="dropdown"
                href="/architecture"
                aria-expanded="false"
              >
                {" "}
                <span className="item_outer">
                  <span className="item_text">
                    Architecture<span className="caret"></span>
                  </span>
                </span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="/architecture">2D Floor Plan</a>
                </li>
                <li>
                  <a href="/architecture">3D Floor Plan</a>
                </li>
                <li>
                  <a href="/architecture">3D Elevation</a>
                </li>
                <li>
                  <a href="/architecture">Designing</a>
                </li>
              </ul>
            </li>  **/
            }
            <a className="menu__item menu__item_contact" href="/painting">
              Painting
            </a>
            <a className="menu__item menu__item_contact" href="https://wallpap.in" >
              Wallpapers
            </a>
            <a className="menu__item menu__item_contact" href="#">
              About
            </a>
            <a style={{display:"none"}} className="menu__item menu__item_contact" href="#">
              Contact
            </a>{" "}
          </menu>
          <footer className="menu__footer">
            <form
              className="menu__search"
              action="https://www.incociar.com/search"
              method="get"
            >
              <input
                className="menu__search__field"
                type="text"
                placeholder="Search"
                name="q"
              />
              <button className="menu__search__button" type="submit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="30"
                  viewBox="0 0 34 30"
                >
                  <path
                    fill="#FFF"
                    d="M24.082 11.248l-1.418 1.41 6.516 6.342h-25.18v-17.88h-2v19.88h27.137l-6.465 6.574 1.402 1.426 8.926-9z"
                  ></path>
                </svg>
              </button>
            </form>
            <div className="menu__networks">
              {" "}
              <a
                  className="menu__networks__item"
                  href="https://www.linkedin.com/company/incociar/"
                  target="_blank"
              >
                LinkedIn
              </a>{" "}
              <a
                className="menu__networks__item"
                href="https://www.facebook.com/Incociar"
                target="_blank"
              >
                Facebook
              </a>{" "}
              <a
                className="menu__networks__item"
                href="https://twitter.com/"
                target="_blank"
              >
                Twitter
              </a>{" "}
              <a
                className="menu__networks__item"
                href="https://instagram.com/incociar"
                target="_blank"
              >
                Instagram
              </a>{" "}
              <a
                className="menu__networks__item"
                href="https://youtube.com/@incociar4703"
                target="_blank"
              >
                Youtube
              </a>{" "}
              <a
                  className="menu__networks__item"
                  href="https://pin.it/2hWW8Cu"
                  target="_blank"
              >
                Pinterest
              </a>{" "}
            </div>
          </footer>
        </div>
      </section>
    </>
  );
};
