import React from "react";
import {Config} from "../Config"


export const PaintingIntro = ({banner, paintingServiceImgs}) => {
  return (
    <div
      id="site-page"
      className="site-page interior-page hfeed site"
      style={{backgroundColor: "#fff", marginTop: -140 }}
    >
      <div
        id="site-page__wrapper"
        className="site-page__wrapper wbcontainer--fluid"
      >
        <div id="site-main" className="site-main">
          <main role="main" className="main-content ">
            <div className="site-content wbcol--12">
              <div className="site-content__inner">
                <div
                  role="article"
                  id="post-43611"
                  className="post-43611 page type-page status-publish hentry"
                >
                  <div className="entry__content">
                    <div
                      className="wp-block-cover alignfull has-background-dim-30 has-background-dim wp-block-cover--fullheight wp-block-cover--valignbottom"
                      // style={{backgroundImage:"url(images_home/painting/banners/PaintBanner.jpg)"}}
                      style={{backgroundImage:`url(${Config.IMAGES_BASE}/painting/banners/${banner.image})`}}
                      // style={{backgroundImage:`"url(${Config.IMAGES_BASE}/painting/banners/${image.image})"`}}
                  // image={`"${Config.IMAGES_BASE}/${image.category}/${image.service}/${image.image}"`}

                    >
                      <div className="wp-block-cover__inner-container">
                        <p
                          className="has-text-align-center has-text-color has-large-font-size"
                          style={{color:"#fff"}}
                        >
                          Paint Service
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12" style={{padding: "80px 0"}}>
                      <div className="wbcontainer">
                        <div className="col-sm-8">
                          <h2
                            className="has-text-align-center title--centered"
                            id="h-your-interior-design-project-with-us"
                          >
                            Incociar Painting Services <br /> 360&deg;
                            <br /> painting services for all your needs
                          </h2>
                          <p className="has-text-align-center">
                            Now get designer walls to oomph up the look of your
                            home or office with our expert painting services. We
                            offer carefully tailored painting solutions for
                            projects of all sizes, from small residential walls
                            to large scale industrial projects.
                          </p>
                        </div>
                        <div className="col-sm-4">
                          <div className="col-sm-6">
                            <img
                              // src="images/Paint-Service-Small-Image-1.png"
                              src={`${Config.IMAGES_BASE}/${paintingServiceImgs[0].category}/${paintingServiceImgs[0].service}/${paintingServiceImgs[0].image}`}
                              style={{paddingBottom:20,width:"100%"}}
                            />
                          </div>

                          <div className="col-sm-6">
                            <img
                              // src="images/Paint-Service-Small-Image-2.png"
                              src={`${Config.IMAGES_BASE}/${paintingServiceImgs[1].category}/${paintingServiceImgs[1].service}/${paintingServiceImgs[1].image}`}
                              style={{paddingBottom:20,width:"100%"}}
                            />
                          </div>
                          <div className="col-sm-6">
                            <img
                              // src="images/Paint-Service-Small-Image-5.png"
                              src={`${Config.IMAGES_BASE}/${paintingServiceImgs[2].category}/${paintingServiceImgs[2].service}/${paintingServiceImgs[2].image}`}

                              style={{paddingBottom:20,width:"100%"}}
                            />
                          </div>
                          <div className="col-sm-6">
                            <img
                              // src="images/Paint-Service-Small-Image-4.png"
                              src={`${Config.IMAGES_BASE}/${paintingServiceImgs[3].category}/${paintingServiceImgs[3].service}/${paintingServiceImgs[3].image}`}

                              style={{paddingBottom:20,width:"100%"}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
