import React from "react";
import {Config} from "../Config"

export const PaintingServiceCards = ({interiorImg,exteriorImg,commericialImg}) => {
  console.log("PaintingServiceCards",interiorImg,exteriorImg,commericialImg);
  return (
    <>
      <div id="two-grid" className=" " style={{padding: "80px 0",background: "#d8d8d8"}}>
        <div className="qodef-elements-holder   qodef-two-columns  qodef-responsive-mode-1024">
          <div
            className="qodef-eh-item"
            data-item-class="qodef-eh-custom-1113"
            data-1366-1600="100px 105px 100px 80px"
            data-1024-1366="110px 91px 140px 80px"
            data-768-1024="112px 154px 139px 118px"
            data-680-768="108px 106px 139px 77px"
            data-680="111px 20px 139px 20px"
          >
            <div className="qodef-eh-item-inner">
              <div
                className="qodef-eh-item-content qodef-eh-custom-4386"
                style={{padding:"100px 119px 100px 119px"}}
              >
                <h2
                  className="has-text-align-center title--centered"
                  id="h-your-interior-design-project-with-us"
                >
                  Interior Painting{" "}
                </h2>
                <p className="has-text-align-center">
                  Paint the interiors with elegant colors, matte or velvetty
                  finnese, and quirky patterns and textures
                </p>
              </div>
            </div>
          </div>
          <div
            className="qodef-eh-item"
            // style={{backgroundImage: "url(images/Paint-Image-1.png)"}}
            style={{backgroundImage:`url(${Config.IMAGES_BASE}/painting/Interior%20Painting/${interiorImg.image})`}}

            data-item-class="qodef-eh-custom-2975"
            data-768-1024="220px 0px"
            data-680-768="220px 0px"
            data-680="250px 0px"
          >
            <div className="qodef-eh-item-content qodef-eh-custom-8336"> </div>
          </div>
        </div>
        <div className="qodef-elements-holder   qodef-two-columns  qodef-responsive-mode-1024">
          <div
            className="qodef-eh-item dview"
            // style={{backgroundImage: `url(images/ExteriorPainting.jpg)`}}
            // style={{backgroundImage:`url(${Config.IMAGES_BASE}/${exteriorImg.category}/${exteriorImg.service}/${exteriorImg.image})`}}
            style={{backgroundImage:`url(${Config.IMAGES_BASE}/painting/Exterior%20Painting/${exteriorImg.image})`}}
            data-item-class="qodef-eh-custom-8336"
            data-768-1024="220px 0px"
            data-680-768="220px 0px"
            data-680="250px 0px"
          >
            <div className="qodef-eh-item-content qodef-eh-custom-8336"> </div>
          </div>
          <div
            className="qodef-eh-item"
            data-item-class="qodef-eh-custom-4386"
            data-1366-1600="100px 85px 100px 79px"
            data-1024-1366="132px 70px 162px 80px"
            data-768-1024="141px 108px 172px 120px"
            data-680-768="140px 106px 166px 82px"
            data-680="140px 20px 166px 20px"
          >
            <div className="qodef-eh-item-inner">
              <div
                className="qodef-eh-item-content qodef-eh-custom-4386"
                style={{padding:"100px 119px 100px 119px"}}
              >
                <h2
                  className="has-text-align-center title--centered"
                  id="h-your-interior-design-project-with-us"
                >
                  Exterior Painting{" "}
                </h2>
                <p className="has-text-align-center">
                  Paint the exterior walls with top-grade colors and add an
                  extra layer of protection against harsh weather, pollution,
                  and damp
                </p>
              </div>
            </div>
          </div>
          <div
            className="qodef-eh-item mview"
            // style= {{backgroundImage: "url(images/ExteriorPainting.jpg)"}}
            style={{backgroundImage:`url(${Config.IMAGES_BASE}/painting/Exterior%20Painting/${exteriorImg.image})`}}

            data-item-class="qodef-eh-custom-8336"
            data-768-1024="220px 0px"
            data-680-768="220px 0px"
            data-680="250px 0px"
          >
            <div className="qodef-eh-item-content qodef-eh-custom-8336"> </div>
          </div>
        </div>
        <div className="qodef-elements-holder   qodef-two-columns  qodef-responsive-mode-1024">
          <div
            className="qodef-eh-item"
            data-item-class="qodef-eh-custom-1113"
            data-1366-1600="100px 105px 100px 80px"
            data-1024-1366="110px 91px 140px 80px"
            data-768-1024="112px 154px 139px 118px"
            data-680-768="108px 106px 139px 77px"
            data-680="111px 20px 139px 20px"
          >
            <div className="qodef-eh-item-inner">
              <div
                className="qodef-eh-item-content qodef-eh-custom-4386"
                style={{padding:"100px 119px 100px 119px"}}
              >
                <h2
                  className="has-text-align-center title--centered"
                  id="h-your-interior-design-project-with-us"
                >
                  Commercial Painting{" "}
                </h2>
                <p className="has-text-align-center">
                  Redesign the look of the commercial spaces with our
                  state-of-the-art commercial painting services
                </p>
              </div>
            </div>
          </div>
          <div
            className="qodef-eh-item"
            // style={{backgroundImage: "url(images/CommercialPainting.jpg)"}}
            style={{backgroundImage:`url(${Config.IMAGES_BASE}/painting/Commercial%20Painting/${commericialImg.image})`}}

            data-item-class="qodef-eh-custom-2975"
            data-768-1024="220px 0px"
            data-680-768="220px 0px"
            data-680="250px 0px"
          >
            <div className="qodef-eh-item-content qodef-eh-custom-8336"> </div>
          </div>
        </div>
      </div>
      <div style={{background:"black",padding:"80px 0"}} className="col-sm-12">
        <div className="wbcontainer">
          <div className="col-sm-6">
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", minHeight: "280px"}}>
              <img
                itemProp="image"
                className="qodef-dark-logo"
                src="/images/logos/full_margin_transparent_white.png"
                alt="dark logo"
              />{" "}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="wp-block-column">
              <h3 className="has-text-align-center"> &nbsp; </h3>
              <p style={{color:"white"}} className="has-text-align-center">
                Get accurate pricing for your painting project in just a click
              </p>
              <br />
              <div className="wp-block-buttons aligncenter">
                <div className=" ">
                  <button className="custom-btn3">
                    <a style={{color:"white"}}>Estimate </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
