import React from "react";

export const Intro = ({text}) => {
  return (
    <div className="qodef-row-grid-section-wrapper ">
      <div className="qodef-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_about">
          <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-2 vc_col-lg-8">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="vc_empty_space" style={{height: 100}}>
                  <span className="vc_empty_space_inner"></span>
                </div>
                <div
                  className="qodef-section-title-holder  qodef-st-corners qodef-st-title-left qodef-st-normal-space  "
                  style={{ textAlign: "center" }}
                >
                  <div className="qodef-st-inner">
                    {" "}
                    <span className="qodef-corner-line line-1"></span>{" "}
                    <span className="qodef-corner-line line-2"></span>{" "}
                    <span className="qodef-corner-line line-3"></span>{" "}
                    <span className="qodef-corner-line line-4"></span>{" "}
                    <span className="qodef-st-text">
                      {text.title}
                    </span>
                  </div>
                </div>
                <div className="vc_empty_space" style={{height: 50}}>
                  <span className="vc_empty_space_inner"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
