export const AboutPg = () =>
            <>
            <div id="site-page" className="site-page interior-page hfeed site" style={{backgroundColor: "#fff"}}>
                <div id="site-page__wrapper" className="site-page__wrapper wbcontainer--fluid">
                    <div id="site-main" className="site-main">
                        <main role="main" className="main-content ">
                            <div className="site-content wbcol--12">
                                <div className="site-content__inner">
                                    <article role="article" id="post-43611"
                                             className="post-43611 page type-page status-publish hentry">
                                        <div className="entry__content">
                                            <div
                                                className="wp-block-cover alignfull has-background-dim-30 has-background-dim wp-block-cover--fullheight wp-block-cover--valignbottom"
                                                style={{backgroundImage:"url(/images/banners/about_banner.jpeg)"}}>
                                                <div className="wp-block-cover__inner-container">
                                                    <div className="has-text-align-center has-text-color has-large-font-size"
                                                       style={{color:"#fff"}}><span style={{wordWrap:"break-word"}}>Incociar Interior Design Service</span></div>
                                                </div>
                                            </div>

                                            <div style={{ height:"100px" }} aria-hidden="true"
                                                 className="wp-block-spacer"></div>

                                            <div
                                                className="wbcontainer wp-block-columns espblock-slidetitleintro + espblock-slidetitleintro">
                                                <div className="wp-block-column">
                                                    <figure style={{display: "none"}} className="wp-block-image size-large"><img width="913"
                                                                                                       height="1144"
                                                                                                       src="https://www.esperiri.com/wp-content/uploads/2020/05/famous-italian-interior-designers-esperiri-milano.jpg"
                                                                                                       alt="high end interior design and moodboard composition"
                                                                                                       className="wp-image-44045"
                                                                                                       srcSet="https://www.esperiri.com/wp-content/uploads/2020/05/famous-italian-interior-designers-esperiri-milano.jpg 913w, https://www.esperiri.com/wp-content/uploads/2020/05/famous-italian-interior-designers-esperiri-milano.jpg 613w, https://www.esperiri.com/wp-content/uploads/2020/05/famous-italian-interior-designers-esperiri-milano.jpg 600w"
                                                                                                       sizes="(max-width: 913px) 100vw, 913px"/>
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h1 id="h-italian-interior-design">Incocicar Design
                                                        Professionals</h1>
                                                    <h4 id="h-boutique-design-studio-in-milan">Design Studio in
                                                        Hyderabad</h4>
                                                    <p>INCOCIAR, led by Abdul Rahman Shakeel and Mohd Farhan Shajeel, is
                                                        an integral part of “Nimal Maula Industries”. It is a prominent
                                                        firm offering a broad spectrum of end-to-end infrastructure
                                                        solutions through a wide range of services.</p>
                                                    <p>It began with a vision to serve you by designing professional yet
                                                        personalized designs for your home/office interiors and
                                                        architecture which helped INCOCIAR to become the #01
                                                        Infrastructure Company in India.</p>
                                                    <p>Literally planning each and every stage from designing the
                                                        architecture of your home or office to adding a finishing touch
                                                        to delivery at your place, our in-house multi-talented team does
                                                        its best job and we ensure that you “feel proud” once you are
                                                        all done with your interiors and infrastructure.</p>
                                                    <p>At INCOCIAR, we have mastered creating a perfect fusion of art,
                                                        engineering, and advanced technology. We integrate them with
                                                        your vision enabling us to develop back-to-back masterpieces and
                                                        the infrastructure that is not just beautiful but reflects your
                                                        soul in it!</p>
                                                </div>
                                            </div>

                                            <div style={{height:"100px"}}  aria-hidden="true"
                                                 className="wp-block-spacer"></div>


                                            <h2 className="has-text-align-center title--centered">Our Mission …</h2>
                                            <div className="wbcontainer">
                                                <p style={{textTransform: "capitalize",fontStyle: "italic",lineHeight: "1.6"}}>
                                                    We are dedicated to providing you with the utmost satisfaction by
                                                    designing a “not just beautiful” infrastructure that <strong>reflects
                                                    your personality! </strong></p>
                                                <p>Our main goal is to primarily collaborate with you to understand your
                                                    problems, then come up with a solution “designed just for you” by
                                                    leveraging art, technology, and creative thinking.</p>
                                            </div>

                                            <div style={{height:"100px"}}  aria-hidden="true"
                                                 className="wp-block-spacer"></div>

                                            <h2 className="has-text-align-center title--centered"
                                                id="h-high-end-interior-design">... and Values</h2>

                                            <div className="wbcontainer wp-block-columns espblock-2col">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large"><img width="1143"
                                                                                                       height="1143"
                                                                                                       src="/images/best-italian-interior-designers-esperiri-milano-min.jpg"
                                                                                                       alt="interior design experience and innovative solutions"
                                                                                                       className="wp-image-43994"
                                                                                                       sizes="(max-width: 1143px) 100vw, 1143px"/>
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h3 id="h-fresh-design-expertise">Quality</h3>
                                                    <p>- For us, quality is not about now. It is about beating the “test
                                                        of time” not just in terms of looking perfect for years but in
                                                        style for eternity! And from designing the architecture of your
                                                        place to adding finishing touches, our team ensures that you
                                                        must “feel proud” to collaborate with us and let us take the
                                                        chance of designing your life’s interiors...</p>
                                                </div>
                                            </div>

                                            <div
                                                className="wbcontainer wp-block-columns espblock-2col + espblock-2col--reversed">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large"><img width="1143"
                                                                                                       height="1145"
                                                                                                       src="/images/professional-italian-interior-designers-esperiri-milano-min.jpg"
                                                                                                       alt="interior design experience and innovative solutions"
                                                                                                       className="wp-image-43996"
                                                                                                       sizes="(max-width: 1143px) 100vw, 1143px"/>
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h3 id="h-unique-turnkey-projects">Integrity</h3>
                                                    <p> INCOCIAR is synonymous with personalised creativity with
                                                        reliability, and we put efforts into giving end-to-end
                                                        infrastructure services by considering your place as our own! We
                                                        consistently try to design magnificent architecture and
                                                        interiors at every stage and optimise the use of your space and
                                                        resources during overall construction in the most efficient
                                                        way.</p>
                                                </div>
                                            </div>
                                            <div className="wbcontainer wp-block-columns espblock-2col">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-image size-large"><img width="1143"
                                                                                                       height="1143"
                                                                                                       src="/images/italian-interior-designers-italian-shopping-tour-milano-min.jpg"
                                                                                                       alt="interior design experience and innovative solutions"
                                                                                                       className="wp-image-43994"
                                                    />
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h3 id="h-fresh-design-expertise">Beauty</h3>
                                                    <p> The core matters, but it comes later! The exterior looks and
                                                        beauty of your place’s architecture and interiors will always
                                                        win your friends' hearts via eyes and make them WOW at a glance…
                                                        We understand this and work accordingly, by crafting every inch
                                                        of your place in perfect tune with you. </p>
                                                </div>
                                            </div>

                                            <div style={{height:"100px" }}  aria-hidden="true"
                                                 className="wp-block-spacer"></div>

                                            <h2 className="has-text-align-center title--centered"
                                                id="h-high-end-interior-design">The Founders</h2>

                                            <div style={{height:"20px" }}  aria-hidden="true"
                                                 className="wp-block-spacer"></div>

                                            <div className="wbcontainer wp-block-columns  has-2-columns valign--center">

                                                <div className="wp-block-column">
                                                    <h3 className="has-text-align-left">Abdul Rahman Shakeel</h3>

                                                    <p className="has-text-align-left">He is the Co-Founder & Head
                                                        of Management at INCOCIAR. With his extreme talent in key
                                                        decision making & team management, he is taking the company
                                                        to new levels. He pursued his degree in BBA from Informatics
                                                        Singapore and MBA from Osmania University.</p>
                                                    <p>He believes that building personalized projects plays a key
                                                        role in reflecting individuals in their home/office
                                                        architect and interiors which results in 110% customer’s
                                                        satisfaction and helps a company to grow exponentially!</p>
                                                </div>

                                                <div className="wp-block-column">
                                                    <h3 className="has-text-align-left">Mohammed Farhan Shajeel</h3>
                                                    <p>He is the Co-Founder & Director of Nimal Maula Industries
                                                        Architect And Interior Designer - INCOCIAR. He graduated from
                                                        JNFAU and has demonstrated a history of working in the
                                                        architecture & planning industry for years. He is very skilled
                                                        in Business Development, Microsoft Office, Architecture, and
                                                        Project Planning.</p>
                                                    <p>He believes in providing the quality services to every client and
                                                        building the home/office exactly of their dream which helped his
                                                        company to become one of the top-rated infrastructure companies
                                                        in India.</p>
                                                </div>

                                            </div>

                                            <div style={{height:"100px" }}  aria-hidden="true"
                                                 className="wp-block-spacer"></div>


                                            <h2 className="has-text-align-center title--centered"
                                                id="h-our-project-selection">Incociar Interior Design Studio</h2>
                                            <p style={{marginBottom: "20px"}} className="has-text-align-center">Discover
                                                the unique space where our interior designers work together to create
                                                inspiring interiors.</p>
                                            <figure id="discover_figure" style={{marginBottom: "0 10%"}}>
                                                    <img id="discover_img" src="images/about_banner2.jpg"
                                                         style={{width: "100%",position: "absolute",left: "0",top:"0",display: "none"}}/>
                                            </figure>

                                            <div style={{height:"30px"}}  aria-hidden="true"
                                                 className="wp-block-spacer"></div>

                                            <div style={{display:"none"}} className="wbcontainer wp-block-buttons aligncenter">
                                                <div className="" style={{margin: "50px"}}>
                                                    <button className="custom-btn3">
                                                        <a style={{color: "white"}}>Discover More</a>
                                                    </button>
                                                </div>
                                            </div>

                                            <div style={{height:"30px" }}  aria-hidden="true"
                                                 className="wp-block-spacer"></div>


                                            <div className="wbcontainer wp-block-columns espblock-bannerbg">
                                                <div className="wp-block-column">
                                                    <figure className="wp-block-gallery columns-2 is-cropped">
                                                        <ul className="blocks-gallery-grid">
                                                            <li className="blocks-gallery-item">
                                                                <figure><img width="1146" height="764"
                                                                             src="images/Aboutproject1.jpg" alt=""
                                                                             className="wp-image-44025"
                                                                             srcSet="images/Aboutproject1.jpg 1146w"/>
                                                                </figure>
                                                            </li>
                                                            <li className="blocks-gallery-item">
                                                                <figure><img width="1146" height="764"
                                                                             src="images/Aboutproject2.jpg" alt=""
                                                                             className="wp-image-44028"
                                                                             srcSet="images/Aboutproject2.jpg 1146w "/>
                                                                </figure>
                                                            </li>
                                                        </ul>
                                                    </figure>
                                                </div>
                                                <div className="wp-block-column">
                                                    <h3 id="h-trustworthy-suppliers">Our Projects</h3>
                                                    <p>The package we offer you for a high-end interior design project
                                                        is complete in every part. Our network of contractors and
                                                        professionals in the world of high-end interior design, such as
                                                        marble workers, restorers, plumbers and lighting engineers,
                                                        allows us to guarantee you the executive realization of both a
                                                        new construction and a renovation project. We will ensure a
                                                        total-look project and its complete implementation.</p>
                                                    <div style={{height:"20px" }}  aria-hidden="true"
                                                         className="wp-block-spacer"></div>
                                                    <div style={{display:"none"}} className="wp-block-buttons aligncenter">
                                                        <div>
                                                            <button className="custom-btn3">
                                                                <a style={{color: "white"}}>Discover More</a>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div style={{height:"50px"}} aria-hidden="true"
                                                 className="wp-block-spacer"></div>


                                        </div>
                                    </article>
                                </div>
                            </div>
                        </main>
                    </div>

                </div>
            </div>
        </>

