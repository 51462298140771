import React from "react";

export const FullPackage = ({color}) => {
  return (
    <div
      className="survey__service-selection service-selection"
      style={{ background: "black" }}
    >
      <div style={{ width: "100%" }}>
        <div
          className="survey__service-selection service-selection"
          style={{ background: "black", padding: 0 }}
        >
          <div
            style={{ background: "black", padding: "80px 0" }}
            className="col-sm-12"
          >
            <div className="wbcontainer">
              <div className="col-sm-6">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: 280,
                  }}
                >
                  <img
                    itemProp="image"
                    className="qodef-dark-logo"
                    src="/images/logos/full_margin_transparent_white.png"
                    alt="dark logo"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="wp-block-column">
                  <h3 className="has-text-align-center">
                    Incociar Designing Package
                  </h3>
                  <p style={{ color: "white" }} className="has-text-align-center">
                    Get the best Incociar design with an overall package for
                    your best dream project
                  </p>
                  <br />
                  <div className="wp-block-buttons aligncenter">
                    <button id="ovelay-serv-calc" className="custom-btn3" style={{background: color}}>
                      Estimate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
