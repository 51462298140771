import React from 'react'
import {Config} from "../Config"


export const Swiper = ({images}) => {
  return (
    <div className="wbcontainer">
        <div className="swiper mySwiper">
          <div className="swiper-wrapper">

          {images.map((image, idx)=>(
            <div className="swiper-slide">
              {/* <img src="images/BottomImage1.jpg" /> */}
              <img src={`${Config.IMAGES_BASE}/${image.category}/${image.service}/${image.image}`}  />

            </div>
          ))}

            
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
  )
}
