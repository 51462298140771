import React, { useState } from "react";

export const TwoBoxRow = ({
  id,
  title,
  text,
  textPosition,
  image,
  color,
  children,
}) => {

  const [calculatorIsOpen,setCalculatorIsOpen] = useState(false);
  
  const openCalculator = () => {
    setCalculatorIsOpen(true);
    console.log("openCalculator");
  };


  const closeCalculator = () => {
    setCalculatorIsOpen(false);
    console.log("closeCalculator");
  };

  return (
    <div>
      <div className="qodef-elements-holder qodef-two-columns qodef-responsive-mode-1024">
        <div
          className="overlay_cal_div"
          style={{
            transform: calculatorIsOpen
              ? "translate(0px, 0px)"
              : "translate(0px, 100%)",
          }}
        >
          <div className="container">
            <div className="col-xs-12 calc1 calc-close-div" onClick={()=>{closeCalculator()}}>
              {" "}
              <span className="ovelay-calc-close">&times;</span>{" "}
            </div>
            {/* <!-- overlay cal 1--> */}
            {children}
          </div>
        </div>

        <div
          className="qodef-eh-item"
          style={{
            backgroundImage:
              "linear-gradient(rgb(255 255 255 / 65%), rgb(255 255 255 / 65%)),url(images/greendots.jpg)",
            float: textPosition,
          }}
          data-item-class="qodef-eh-custom-1113"
          data-1366-1600="109px 105px 138px 80px"
          data-1024-1366="110px 91px 140px 80px"
          data-768-1024="112px 154px 139px 118px"
          data-680-768="108px 106px 139px 77px"
          data-680="111px 20px 139px 20px"
        >
          <div className="qodef-eh-item-inner">
            <div
              className="qodef-eh-item-content qodef-eh-custom-1113"
              style={{ padding: "106px 119px 138px 119px" }}
            >
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h2 className="TitlingGothicSkyline" style={{ color: color }}>
                    {title.toUpperCase()}
                  </h2>
                  <h3>{text}</h3>{" "}
                </div>
              </div>
              <div className="vc_empty_space" style={{ height: 10 }}>
                <span className="vc_empty_space_inner"></span>
              </div>
              <div className="vc_empty_space" style={{ height: 17 }}>
                <button
                  id={id}
                  className="custom-btn3"
                  style={{ background: color }}
                  onClick={() => {
                    openCalculator();
                  }}
                >
                  Estimate
                </button>
              </div>
              <div className="vc_empty_space" style={{ height: 17 }}>
                <span className="vc_empty_space_inner"></span>
              </div>
              <div className="vc_empty_space" style={{ height: 17 }}>
                <span className="vc_empty_space_inner"></span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="qodef-eh-item"
          style={{
            backgroundImage:
              "linear-gradient(rgb(255 255 255 / 65%), rgb(255 255 255 / 65%)),url(images/greendots.jpg)",
            float: textPosition,
          }}
          data-item-class="qodef-eh-custom-2975"
          data-768-1024="220px 0px"
          data-680-768="220px 0px"
          data-680="250px 0px"
        >
          <div
            className="qodef-eh-item-inner qodef-eh-item-inner-margin"
            style={{
              background: `url(${image}) center no-repeat`,
              backgroundSize: "contain",
            }}
          >
            <div
              className="qodef-eh-item-content qodef-eh-custom-2975"
              style={{ padding: "280px 0px" }}
            >
              {" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
