import React, { useEffect, useState } from "react";

export const EstimatesPg = () => {
  const [estimates, setEstimates] = useState(JSON.parse(localStorage.getItem("estimates")) || []);
 
  return (
    <>
    <link href="css/estimates.css" rel='stylesheet' type='text/css' />

    <div className="container-fluid">
      <div className="">
        <h6 className="boq-title default-color bold">
          My Estimates <span> - ({estimates.length})</span>
        </h6>
        <div className="infinite-scroll-component__outerdiv">
          <div
            className="infinite-scroll-component "
            style={{height: "auto", overflow: "auto"}}
          >
            <ul className="tw-mt-4">
              {estimates.map((ele,idx)=>(
                <li key={idx} className="estimation-item">
                <div className="tw-flex">
                  <div
                    className="left"
                    style={{backgroundImage:"url(home/images/banners/Homepage-1banner.jpg)",}}
                  ></div>
                  <div className="right">
                    <div className="info">
                      <h5 className="boq-title default-color bold">{ele.category} Estimate {idx+1}</h5>
                      <p>
                        {/* <span className="line-item text-2-color">2BHK</span> */}
                        <span className="line-item text-2-color">{ele.builtupArea} sqft</span>
                        <span className="line-item text-2-color">
                          <span className="">
                            <span>&#8377;2,273</span>
                          </span>
                          /sqft
                        </span>
                        <span className="line-item text-2-color">
                          19 - 20 weeks of design &amp; fit-out
                        </span>
                      </p>
                      <p className="text-2-color modified">
                        Last updated 2 months ago
                      </p>
                    </div>
                    <ul>
                      
                      {Object.keys(ele.estimates)
                      .filter((svc) => ele.estimates[svc] != 0)
                      .map((svc,idx)=>(
                      <li>
                        {svc}
                        <span className="">
                          <span>&#8377;{ele.estimates[svc]}</span>
                          {/* <span className="value tw-ml-1">Lacs </span> */}
                        </span>
                      </li>
                      ))}

                      <li>
                        Total Cost
                        <span className="">
                          <span>&#8377;{ele.total}</span>
                          {/* <span className="value tw-ml-1">Lacs </span> */}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              ))}
              
            </ul>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
