import React, { useEffect, useState, useContext, useReducer } from "react";
import { PaintCard } from "./PaintCard";
import { RoomWindowsDoors } from "./RoomWindowsDoors";

export const RoomDim = ({roomsDetails, setRoomsDetails, paint, setPaint, texture, setTexture, primer, setPrimer, projectType, space, putty}) => {
  console.log("roomsDetails::",roomsDetails, setRoomsDetails);
  const newRoom = {
    name : "Room 1",
    width : 0,
    len : 0,
    height : 0,
    doors : {
      wallA:[],
      wallB:[],
      wallC:[],
      wallD:[],
    },
    windows : {
      wallA:[],
      wallB:[],
      wallC:[],
      wallD:[],
    },
    wallA : { work: "Paint", width : 0, height : 0 , wallArea : 0, noWallArea : 0, paintArea :  0, rate: 0, price: 0 },
    wallB : { work: "Paint", width : 0, height : 0 , wallArea : 0, noWallArea : 0, paintArea :  0, rate: 0, price: 0 },
    wallC : { work: "Paint", width : 0, height : 0 , wallArea : 0, noWallArea : 0, paintArea :  0, rate: 0, price: 0 },
    wallD : { work: "Paint", width : 0, height : 0 , wallArea : 0, noWallArea : 0, paintArea :  0, rate: 0, price: 0 },
    includeCeiling : false,
    paintArea : 0,
    wallArea : 0,
    noWallArea : 0,
  }
  function addNewRoom(){
    setRoomsDetails([...roomsDetails,{...newRoom, name: `Room ${roomsDetails.length+1}`}]);
  }

  const [selected, setSelected] = useState(0);
  function selectTab(idx){
    setSelected(idx);
  }
  const [editing, setEditing] = useState(false);

  function saveRoomName(val){
    let _roomsDetails = [...roomsDetails];
    _roomsDetails[selected].name = val;
    setRoomsDetails(_roomsDetails);
  }
  
  function changeRoomDetails(idx = selected, details = roomsDetails[selected], $paint = paint, $texture = texture, $primer=primer, $projectType=projectType, $space = space){
    let _roomsDetails = [...roomsDetails];
    _roomsDetails[selected] = details;
    _roomsDetails[selected].wallArea = (Number(details.len) + Number(details.width))*2 * Number(details.height);
    
    _roomsDetails[selected].noWallArea = 0;
    _roomsDetails[selected].paintArea = 0;
    _roomsDetails[selected].totalPrice = 0;

    ["wallA","wallB","wallC","wallD"].forEach((wall)=>{
      _roomsDetails[selected][wall].wallArea = _roomsDetails[selected][wall].width * _roomsDetails[selected][wall].height;
      _roomsDetails[selected][wall].noWallArea = 0;
      _roomsDetails[selected].windows[wall].forEach((win)=>{
        _roomsDetails[selected][wall].noWallArea += (Number(win.width*win.height));
      })
      _roomsDetails[selected].doors[wall].forEach((door)=>{
        _roomsDetails[selected][wall].noWallArea += (Number(door.width*door.height));
      })
      if (_roomsDetails[selected][wall].work != "None"){
        _roomsDetails[selected][wall].paintArea = (_roomsDetails[selected][wall].wallArea - 
          _roomsDetails[selected][wall].noWallArea);
      }else{
        _roomsDetails[selected][wall].paintArea = 0;
      } 
      if (_roomsDetails[selected][wall].work == "Paint"){
        _roomsDetails[selected][wall].rate = $paint.selectedMaterial.rate;
      }else if (_roomsDetails[selected][wall].work == "Texture"){
        _roomsDetails[selected][wall].rate = $texture.selectedMaterial.rate;
      }else if (_roomsDetails[selected][wall].work == "Wallpaper"){
        // debugger;
        _roomsDetails[selected][wall].rate = $primer.selectedMaterial.rate;
      }else {
        _roomsDetails[selected][wall].rate = 0;
      }
      _roomsDetails[selected].noWallArea += _roomsDetails[selected][wall].noWallArea;
      _roomsDetails[selected].paintArea += Number(_roomsDetails[selected][wall].paintArea) - 0;

      

      //calculate price
      
      // const nCoats = 2;
      // if ($projectType == "fresh"){
      //   _roomsDetails[selected][wall].price += putty["Standard"][0].rate;
      //   _roomsDetails[selected][wall].price += primer.selectedMaterial.rate;

      // }else{
      //   _roomsDetails[selected][wall].price += putty["Standard"][0].rate;
      // }
      
      _roomsDetails[selected][wall].price = (_roomsDetails[selected][wall].paintArea * _roomsDetails[selected][wall].rate)-0;
      _roomsDetails[selected].totalPrice += _roomsDetails[selected][wall].price;
    });
    //ceiling
    if (_roomsDetails[selected].includeCeiling) {
      _roomsDetails[selected].ceilingArea = ((_roomsDetails[selected].width) * (_roomsDetails[selected].len)) - 0;
      _roomsDetails[selected].paintArea = (_roomsDetails[selected].paintArea + _roomsDetails[selected].ceilingArea) -0;
      _roomsDetails[selected].totalPrice += _roomsDetails[selected].ceilingArea * $paint.selectedMaterial.rate;
    }
    
    setRoomsDetails(_roomsDetails);
  }
  function changePaint(val){
    setPaint(val);
    // changeRoomDetails(selected,roomsDetails[selected],val);
  }
  function changeTexture(val){
    setTexture(val);
    // changeRoomDetails(selected,roomsDetails[selected],paint, val);
  }

  useEffect(async()=>{
    console.log("recalculate", );
    if (paint.selectedMaterial && texture.selectedMaterial)
      changeRoomDetails();
  },[paint, texture, primer])

  useEffect(async()=>{
    console.log("recalculate", selected,roomsDetails[selected],paint, texture, projectType, space );
    if (paint.selectedMaterial && texture.selectedMaterial)
      changeRoomDetails(selected,roomsDetails[selected],paint, texture, primer, projectType, space);
  },[projectType, space])

  return (
    <>
    <div className="a-cal card a_cal_interior">
      <h5>Enter the dimensions of each surface area</h5>
      <hr />
      <div className="row">
        {/* <!-- box-design-fields --> */}

        <ul className="room-tabs m-0">
          {(roomsDetails).map((room, idx)=>(
            <span key = {idx} id="spanroom_tabs_{idx}">
              <a
                className={idx==selected? "minitab active":"minitab"}
                onClick={()=>{selectTab(idx)}}
              >
                {" "}
                {idx==selected&&editing?"": <span className="kw"> {room.name}&nbsp;&nbsp;&nbsp;</span>}
                {idx==selected?
                  <>
                    {editing? <input 
                    value={room.name} 
                    style={{background: "#337ab7", width:room.name.length*10, height:20}}
                    onChange={(e)=>saveRoomName(e.target.value)}/> 
                    :""}
                    <i className="fas fa-pen"
                    onClick={()=>{setEditing(!editing)}}></i>
                  </>
                  :
                  ""
                }
              </a>
            </span>
          ))}

          <a 
            // href="javascript"
            id="addlink" 
            onClick={()=>{addNewRoom()}}
          >
            {" "}
            <span className="kw">
              Add Room <i className="fas fa-plus-circle"></i>
            </span>{" "}
          </a>
        </ul>
        <div className="clearfix"></div>

        <div className="col-sm-12 div-section div_section_root" id="newbox1">
          <h5>Room Dimensions</h5>
          <div className="bg-light clearfix p-25 roomdimensions">
            <div className="col-xs-6 col-sm-4">
              <label className="radio-inline">
                <label for="usr">Width</label>
                <input type="number" name="width" value={roomsDetails[selected].width}
                  onChange={(e) => {
                    let _roomDetails = {...roomsDetails[selected]};
                    _roomDetails.width = e.target.value;
                    _roomDetails.wallA.width = _roomDetails.wallC.width = Number(e.target.value);
                    changeRoomDetails(selected, _roomDetails)}
                  }
                />
                &nbsp; <span className="ipunits">Ft.</span>{" "}
              </label>
            </div>
            <div className="col-xs-6 col-sm-4">
              <label className="radio-inline">
                <label for="usr">Length</label>
                <input type="number" name="length" value={roomsDetails[selected].len}
                onChange={(e) => {
                  let _roomDetails = {...roomsDetails[selected]};
                  _roomDetails.len = e.target.value;
                  _roomDetails.wallB.width = _roomDetails.wallD.width = Number(e.target.value);
                  changeRoomDetails(selected, _roomDetails)}
                }
                />
                &nbsp; <span className="ipunits">Ft.</span>{" "}
              </label>
            </div>
            <div className="col-xs-12 col-sm-4">
              <label className="radio-inline">
                <label>Height</label>
                <input type="number" name="height"  value={roomsDetails[selected].height}
                onChange={(e) => {
                  let _roomDetails = {...roomsDetails[selected]};
                  _roomDetails.height = (e.target.value);
                  _roomDetails.wallA.height = _roomDetails.height;
                  _roomDetails.wallB.height = _roomDetails.height;
                  _roomDetails.wallC.height = _roomDetails.height;
                  _roomDetails.wallD.height = _roomDetails.height;
                  changeRoomDetails(selected, _roomDetails)}
                }
                />
                &nbsp; <span className="ipunits">Ft.</span>{" "}
              </label>
            </div>

            <RoomWindowsDoors
              roomDetails={roomsDetails[selected]}
              changeRoomDetails={(details)=>changeRoomDetails(selected, details)}/>

            <div className="clearfix"></div>
            <hr />
            <div className="col-sm-6 text-center">
              <div className="parent-box mb-20">
                <p  className={roomsDetails[selected].wallA.work == "None"?"topSide":"topSide wall-color-top"}>
                  {/* <!---->  */}
                  <span className="sidespan">
                    Wall A(<span className="wall-alt">{roomsDetails[selected].wallA.paintArea && roomsDetails[selected].wallA.paintArea.toFixed(1)}</span>ft.)
                  </span>
                </p>
                <p  className={roomsDetails[selected].wallB.work == "None"?"rightSide":"rightSide wall-color-right"}>
                  {/* <!---->  */}
                  <span className="sidespan">
                    Wall B(<span className="wall-opp">{roomsDetails[selected].wallB.paintArea  && roomsDetails[selected].wallB.paintArea.toFixed(1)}</span>ft.)
                  </span>
                </p>
                <p  className={roomsDetails[selected].wallC.work == "None"?"bottomSide":"bottomSide wall-color-bottom"}>
                  {/* <!---->  */}
                  <span className="sidespan">
                    Wall C(<span className="wall-alt">{roomsDetails[selected].wallC.paintArea  && roomsDetails[selected].wallC.paintArea.toFixed(1)}</span>ft.)
                  </span>
                </p>
                <p  className={roomsDetails[selected].wallD.work == "None"?"leftSide":"leftSide wall-color-left"}>
                  {/* <!---->  */}
                  <span className="sidespan">
                    Wall D(<span className="wall-opp">{roomsDetails[selected].wallD.paintArea  && roomsDetails[selected].wallD.paintArea.toFixed(1)}</span>ft.)
                  </span>
                </p>
                <div className={roomsDetails[selected].includeCeiling?"child-box wall-color-center":"child-box"}>
                  {/* <!---->  */}
                  <span>
                    <span className="wall-ceil">{roomsDetails[selected].ceilingArea && roomsDetails[selected].ceilingArea.toFixed(1)}</span>sq.ft
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 walls">
              {["A","B","C","D"].map((wName, idx)=>(
              <div key={idx} className="form-group mb-25">
                <label for="sel1">WALL {wName}</label>
                <select value={roomsDetails[selected][`wall${wName}`].work} className="wall-a form-control" id="sel1"
                  onChange={(e) => {
                      let _roomDetails = {...roomsDetails[selected]};
                      let work = e.target.value;
                      _roomDetails[`wall${wName}`].work = work
                      changeRoomDetails(selected, _roomDetails);
                    }}
                >
                  <option value="None">Nothing</option>
                  <option value="Paint">Paint</option>
                  <option value="Texture">Texture</option>
                  <option value="Wallpaper">Wallpaper</option>
                </select>
              </div>
              ))}

              

              <div className="form-group mb-25">
                <label className="cbox_container">
                  <input
                    className="wall-x"
                    type="checkbox"
                    name="includeCeiling"
                    checked={roomsDetails[selected].includeCeiling}
                    onChange={(e) => changeRoomDetails(selected, {...roomsDetails[selected], includeCeiling: !(roomsDetails[selected].includeCeiling)})}
                  />{" "}
                  Include Ceiling
                </label>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>

        {/* <!-- span new rooms in this div--> */}
        <div className="newroom_div"> </div>
        {/* <!-- //box design fields --> */}
      </div>

    </div>
      {
        ( roomsDetails[selected].wallA.work != "Paint" && 
          roomsDetails[selected].wallB.work != "Paint" &&
          roomsDetails[selected].wallC.work != "Paint" &&
          roomsDetails[selected].wallD.work != "Paint" &&
          roomsDetails[selected].includeCeiling != true )?
        "":
        <PaintCard title="Paint" key="advance" value={paint} setValue={changePaint}/>
      }
      {
        ( roomsDetails[selected].wallA.work != "Texture" && 
          roomsDetails[selected].wallB.work != "Texture" &&
          roomsDetails[selected].wallC.work != "Texture" &&
          roomsDetails[selected].wallD.work != "Texture" )?
        "":
        <PaintCard title="Texture" key="texture" value={texture} setValue={changeTexture}/>
      }
        
      {
        ( roomsDetails[selected].wallA.work != "Wallpaper" && 
          roomsDetails[selected].wallB.work != "Wallpaper" &&
          roomsDetails[selected].wallC.work != "Wallpaper" &&
          roomsDetails[selected].wallD.work != "Wallpaper" )?
        "":
        <PaintCard title="Primer(Wallpaper Base) " key="primer" value={primer} setValue={setPrimer}/>}
    </>
  );
};
