import { createContext } from "react";

export const UserCtx = createContext({ user: null, setUser: null,state:null, dispatch:(f)=>f });

export const ACTION = {
  LOGIN: "login",
  LOGOUT: "logout",
  ADD_ESTIMATE: "add_estimate",
  REMOVE_ESTIMATE: "remove_estimate",
  ADD_CART: "add_cart",
  REMOVE_CART: "remove_cart",
  INIT_DATA:'initServerData',
  LOADING:'loadServerData'
};
export const userReducer = (user, action) => {
  switch (action.type) {
    case ACTION.LOGIN:
      localStorage.setItem("email",action.payload);
      return (user?{...user, email:action.payload}:{email:action.payload});
    case ACTION.LOGOUT:
      localStorage.removeItem("email");
      return (user?{...user, email: null}:{email:action.payload});
    case ACTION.INIT_DATA:
      return (user?{...user, serverData: action.payload,loadedServerData:true,loading:false}:{serverData: action.payload,loadedServerData:true,loading:false});
    case ACTION.LOADING:
      return (user?{...user, loading:action.payload.loading}:{loading:action.payload.loading});
    default:
      return user;
  }
};
