import React from "react";
import {Pg} from "../Pg";
import {Config} from "../Config"

export const Banners = ({category, banners}) => {

  return (
    <div>
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="qodef-portfolio-stack-slider-holder">
                <div
                  className="qodef-portfolio-list-holder qodef-grid-list qodef-pl-gallery qodef-one-columns qodef-no-space qodef-pl-stack-slider    qodef-pl-pag-no-pagination"
                  data-type="gallery"
                  data-number-of-columns="one"
                  data-space-between-items="no"
                  data-number-of-items="-1"
                  data-image-proportions="full"
                  data-enable-fixed-proportions="no"
                  data-enable-image-shadow="no"
                  data-tag="homepage"
                  data-orderby="date"
                  data-order="ASC"
                  data-item-style="stack-slider"
                  data-enable-title="yes"
                  data-title-tag="h6"
                  data-title-text-transform="uppercase"
                  data-enable-category="yes"
                  data-enable-count-images="no"
                  data-enable-excerpt="no"
                  data-excerpt-length="20"
                  data-pagination-type="no-pagination"
                  data-filter="no"
                  data-filter-order-by="name"
                  data-enable-article-animation="no"
                  data-portfolio-slider-on="no"
                  data-enable-loop="yes"
                  data-enable-autoplay="yes"
                  data-slider-speed="5000"
                  data-slider-speed-animation="1000"
                  data-enable-navigation="yes"
                  data-enable-pagination="yes"
                  data-enable-mousewheel-scroll="no"
                  data-max-num-pages="0"
                  data-next-page="2"
                >
                  <div className="qodef-pl-inner qodef-outer-space qodef-stack-slider clearfix">

                  { banners.map((banner, idx)=>( 
                  <article key = {idx} className="qodef-pl-item qodef-item-space  qodef-stack-slide post-6336 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-tag-homepage">
                      <div className="qodef-pl-item-inner">
                        {" "}
                        <img
                          width="2187"
                          height="1432"
                          src={`${Config.IMAGES_BASE}/${category}/banners/${banner.image}`}
                          className="attachment-full size-full wp-post-image"
                          alt="Banner"
                          loading="lazy"
                        />
                        <div className="qodef-info-box-data-hidden">
                          <h2>Structural</h2>
                          <a href="#"></a>
                        </div>
                        <a
                          itemProp="url"
                          className="qodef-pli-link qodef-block-drag-link"
                          href="#"
                          target="_blank"
                        ></a>
                      </div>
                    </article>
                    ))}

                    
                    
                  </div>
                </div>
                <div className="qodef-slider-nav" >
                  <div className="qodef-prev">
                    {" "}
                    <span className="qodef-prev-icon lnr lnr-chevron-left"></span>{" "}
                  </div>
                  <div className="qodef-next">
                    {" "}
                    <span className="qodef-next-icon lnr lnr-chevron-right"></span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
