import React from 'react'


window.NM_EVENTS =  {
  eventsTable : {
    "USER_LOGGED_IN" : [],
    "CONFIRMED" : [],
    "SAVE_ESTIMATES" : [],
  },     
  publish : function (event){
    let callbacks = window.NM_EVENTS.eventsTable[event];
    if (!callbacks) {
      console.log("publish:No callbacks for ev:",event);
      return;
    }
    callbacks.forEach((callback)=>{callback()});
  },
  subscribe : function(event, callback){
    let callbacks = window.NM_EVENTS.eventsTable[event];
    if (!callbacks) {
      console.log("subscribe:No callbacks for ev:",event);
      return;
    }
    callbacks.push(callback);
  }
}
