import React, {useEffect, useLayoutEffect, useRef} from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation
} from 'react-router-dom';

import {FileHeader} from "./components/FileHeader";
import {Footer} from "./components/Footer";
import {MobileHeader} from "./components/MobileHeader";
import {SideMenu} from "./components/SideMenu";
import {CivilPg} from "./pages/CivilPg";
import {LoginPg} from './pages/LoginPg';
import {HomePg} from './pages/HomePg';
import {EstimatesPg} from './pages/EstimatesPg';
import {ArchitecturePg} from './pages/ArchitecturePg';
import {PaintingPg} from './pages/PaintingPg';
import {UserCtx, userReducer, ACTION} from './libs/UserCtx';
import {Fragment, useReducer, useState} from 'react';
import {PaintingCalculatorPg} from './pages/PaintingCalculatorPg';
import {InteriorPg} from './pages/InteriorPg';
import {AboutPg} from './pages/AboutPg';

window.NM_CALLBACKS = {};

const UpdateView = (props) => {
    const {mainRef} = props;
    const location = useLocation();
    useEffect(() => {
        if (mainRef.current) {
            if (location.pathname === '/') {
                mainRef.current.style.display = 'none';
            } else {
                mainRef.current.style.display = '';
            }
        }
    }, [location, mainRef])
    return <></>
}

export default React.memo((props) => {
    // const [user, setUser] = useState({email:null});
    const storedEmail = localStorage.getItem("email") || "";
    const [user, dispatch] = useReducer(userReducer, {email: storedEmail, serverData: []});
    const mainRef = useRef();

    return (
        <>
            <UserCtx.Provider value={{user, dispatch, state: user}}>
                <Router>
                    <Routes>
                        <Route path="/" element={<HomePg/>}/>
                    </Routes>
                    <div ref={mainRef}
                         className="home page-template page-template-full-width page-template-full-width-php page page-id-20 qodef-content-is-behind-header qodef-empty-google-api qodef-wide-dropdown-menu-content-in-grid qodef-dark-header qodef-sticky-header-on-scroll-down-up qodef-dropdown-animate-height qodef-menu-area-shadow-disable qodef-menu-area-in-grid-shadow-disable qodef-menu-area-border-disable qodef-menu-area-in-grid-border-disable qodef-logo-area-border-disable qodef-header-vertical-shadow-disable qodef-header-vertical-border-disable qodef-side-menu-slide-from-right qodef-default-mobile-header qodef-sticky-up-mobile-header wpb-js-composer js-comp-ver-6.1 qodef-header-dessau  vc_responsive">
                        <SideMenu/>
                        <div className="qodef-wrapper-inner">
                            <FileHeader/>
                            <MobileHeader/>
                            <a id="qodef-back-to-top" href="#">
            <span className="qodef-icon-stack">
              <span
                  aria-hidden="true"
                  className="qodef-icon-linear-icons lnr lnr-chevron-up "
              ></span>{" "}
            </span>
                            </a>
                            <Routes>
                                <Route path="/login" element={<LoginPg/>}/>
                                <Route path="/civil" element={<CivilPg category="civil" color="#239a95"/>}/>
                                <Route path="/architecture"
                                       element={<ArchitecturePg category="architecture" color="#f17b49"/>}/>
                                <Route path="/painting" element={<PaintingPg category="painting" color="#f17b49"/>}/>
                                <Route path="/painting-calculator" element={<PaintingCalculatorPg/>}/>
                                <Route path="/estimates" element={<EstimatesPg/>}/>
                                <Route path="/interior" element={<InteriorPg/>}/>
                                <Route path="/about" element={<AboutPg/>}/>
                            </Routes>
                            <Footer/>
                        </div>
                    </div>
                    <UpdateView mainRef={mainRef}/>
                </Router>
            </UserCtx.Provider>
        </>
    );
});

